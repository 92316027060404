import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    redirectPath: null,
    isLoggedIn: !!Cookies.get("token"),
    isAdminLoggedIn: !!Cookies.get("adminToken"),
    isSellerLoggedIn: !!Cookies.get("sellerToken"),
    emails: {
      admin: null,
      seller: null,
      user: null,
    },
    userId: null,
    userType: Cookies.get("userType") || null,
    showSearchBar: false,
  },
  reducers: {
    login: (state, action) => {
      const { email, userType,userId } = action.payload;
      if (userType === "admin") {
        state.emails.admin = email;
        state.isAdminLoggedIn = true;
        state.userType = userType;
        // Cookies.set('adminToken', token);
      } else if (userType === "seller") {
        state.emails.seller = email;
        state.isSellerLoggedIn = true;
        state.userType = userType;
        // Cookies.set('sellerToken', token);
      } else {
        state.emails.user = email;
        state.isLoggedIn = true;
        state.userType = userType;
        state.userId=userId;
        // Cookies.set('token', token);
      }
      Cookies.set("userType", userType);
    },
    logout: (state, action) => {
      const userType = action.payload;

      if (userType === "admin") {
        state.isAdminLoggedIn = false;
        state.emails.admin = null;
        Cookies.remove("adminToken");
      } else if (userType === "seller") {
        state.isSellerLoggedIn = false;
        state.emails.seller = null;
        Cookies.remove("sellerToken");
      } else {
        state.isLoggedIn = false;
        state.emails.user = null;
        Cookies.remove("token");
      }

      if (
        !state.isLoggedIn &&
        !state.isAdminLoggedIn &&
        !state.isSellerLoggedIn
      ) {
        state.userType = null;
        Cookies.remove("userType");
      }
    },
    toggleSearchBar: (state, action) => {
      state.showSearchBar = action.payload;
    },
    setRedirectPath:(state, action) => {
      state.redirectPath = action.payload;
    },
  },
});

export const { login, logout,setRedirectPath, toggleSearchBar } = loginSlice.actions;

export const selectIsLoggedIn = (state) => state.login.isLoggedIn;
export const selectIsAdminLoggedIn = (state) => state.login.isAdminLoggedIn;
export const selectIsSellerLoggedIn = (state) => state.login.isSellerLoggedIn;
export const selectEmails = (state) => state.login.emails;
export const selectAdminEmail = (state) => state.login.emails.admin;
export const selectSellerEmail = (state) => state.login.emails.seller;
export const selectUserEmail = (state) => state.login.emails.user;
export const selectUserType = (state) => state.login.userType;
export const selectShowSearchBar = (state) => state.login.showSearchBar;
export const selectUserId = (state) =>state.login.userId;
export default loginSlice.reducer;
