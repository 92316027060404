
// src/App.js
import React from "react";


import UserProfile from "../component/customer/Components/UserProfile";
// Seller Components
import LogIn from "../component/seller/Login";
import Home from "../component/seller/Home";
import UpdateProfile from "../component/seller/UpdateProfile";
import ProductLists from "../component/seller/ProductLists";
import Quotation from "../component/seller/Quotation";
import DashboardSeller from "../component/seller/Dashboard";
import SellerNegotiation from "../component/seller/Negociation";


export const sellerRoutes = [
  { path: "", element: <LogIn /> },
  {
    path: "dashboard",
    element: <Home />,
    children: [
      { path: "", element: <DashboardSeller /> },
      { path: "profile", element: <UserProfile type="seller" /> },
      { path: "negotiation", element: <SellerNegotiation /> },
      { path: "product-list", element: <ProductLists /> },
      // { path: "History", element: <History /> },
      { path: "quotation", element: <Quotation /> },
      { path: "update-profile", element: <UpdateProfile userType="seller" /> },
    ],
  },
];