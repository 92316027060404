import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { verifyToken } from '../../../services/loginActions';
import { selectUserEmail, selectSellerEmail, selectUserId } from '../../../services/loginReducer';
import { useGetUserQuery } from '../../../services/services';
import { useUpdateUserMutation, useFetchGstDataMutation } from '../../../services/userApi';
import {
  EmailOutlined,
  BusinessOutlined,
  PhoneOutlined,
  ReceiptOutlined,
  LocationOnOutlined,
  CloudUploadOutlined,

  CalendarToday
} from '@mui/icons-material';
import { CloseOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Popover, Button } from '@mui/material';

export default function UserProfile({ type }) {
  const email = useSelector(type === "seller" ? selectSellerEmail : selectUserEmail);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const [gstPopoverAnchor, setGstPopoverAnchor] = useState(null);
  const [isGstDialogOpen, setIsGstDialogOpen] = useState(true);
  const [mobileNo, setMobileNo] = useState();
  const [name, setName] = useState();
  const [gstInput, setGstInput] = useState('');
  const [gstError, setGstError] = useState('');
  const {
    data: userData,
    isError,
    isLoading,
    refetch
  } = useGetUserQuery(email);


  const [updateUser] = useUpdateUserMutation();
  const [fetchGstData] = useFetchGstDataMutation();

  React.useEffect(() => {
    dispatch(verifyToken(type === "seller" ? "sellerToken" : "token"));
  }, [dispatch, type]);



  const handleFetchGstData = () => {
    if (userData.gstNo) {
      fetchGstDataAndUpdate(userData.gstNo);
    } else {
      setIsGstDialogOpen(true);
    }
  };

  const fetchGstDataAndUpdate = async (gstNo) => {
    try {
      const gstData = await fetchGstData({ gstNo, userId, mobileNo,name }).unwrap();
      // await updateUser({ ...userData, ...gstData }).unwrap();
      refetch();
      setIsGstDialogOpen(false);
      setGstInput('');
      setGstError('');
    } catch (error) {
      console.error('Failed to fetch GST data:', error);
      setGstError('Failed to fetch GST data. Please check the GST number and try again.');
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profileImage', file);
      formData.append('userId', userId);
      try {
        await updateUser(formData).unwrap();
        refetch();
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    }
  };

  const handleGstDialogSubmit = () => {
    if (gstInput.trim()) {
      fetchGstDataAndUpdate(gstInput.trim());
    } else {
      setGstError('Please enter a valid GST number.');
    }
  };

  const handleRemoveImage = async () => {
    try {
      await updateUser({ profileImage: null }).unwrap();
      refetch();
    } catch (error) {
      console.error('Failed to remove image:', error);
    }
  };

  if (isLoading) return <div className="flex justify-center items-center h-full">Loading...</div>;

  if (isError) return <div className="text-red-500">Error loading profile</div>;

  return (
    <div className="bg-white rounded-lg shadow-md p-6 max-w-4xl mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between mb-6">
        <div className="flex items-center mb-4 md:mb-0">
          <div className="relative">
            <img
              src={userData.profileImage ? `/api/v1/image/${userData?.profileImage}` : "https://via.placeholder.com/150"}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border-4 border-blue-200"
            />
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              className="hidden"
              onChange={handleImageUpload}
            />
            <label htmlFor="imageUpload" className="absolute bottom-0 right-0 bg-blue-500 text-white p-1 rounded-full cursor-pointer">
              <CloudUploadOutlined />
            </label>
          </div>
          <div className="ml-4">
            <h2 className="text-2xl font-bold text-gray-800">{userData?.name || ""} </h2>
            <p className="text-gray-600">{userData?.companyName || ""} <span class={`size-2 ${userData.sts ? 'bg-green-500' : 'bg-red-500'} inline-block  rounded-full me-2`}></span></p>
          </div>
        </div>
        <div className="flex space-x-2">

          {
            !userData?.gstNo &&
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFetchGstData}
            >
              Fetch GST Data
            </Button>
          }

        </div>
      </div>

      <Popover
        open={Boolean(gstPopoverAnchor)}
        anchorEl={gstPopoverAnchor}
        onClose={() => setGstPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="warning">
          <AlertTitle>GST Number Required</AlertTitle>
          Please add your GST number in your profile to fetch GST data.
        </Alert>
      </Popover>

      {isGstDialogOpen && !userData.gstNo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center border-b px-6 py-4">
              <h3 className="text-xl font-semibold text-gray-900">Enter GST and Mobile Number</h3>
              <button
                onClick={() => setIsGstDialogOpen(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <CloseOutlined />
              </button>
            </div>
            <div className="px-6 py-4">
              <div className="mb-4">
                <label htmlFor="yourname" className="block text-sm font-medium text-gray-700 mb-1">
                  Your Name
                </label>
                <input
                  id="yourname"
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${gstError ? 'border-red-500' : 'border-gray-300'
                    }`}
                  placeholder="Enter Your name number"
                />

              </div>
              <div className="mb-4">
                <label htmlFor="mobileno" className="block text-sm font-medium text-gray-700 mb-1">
                  Mobile NO
                </label>
                <input
                  id="mobileno"
                  required
                  type="text"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${gstError ? 'border-red-500' : 'border-gray-300'
                    }`}
                  placeholder="Enter Mobile NO number"
                />

              </div>
              <div className="mb-4">
                <label htmlFor="gstInput" className="block text-sm font-medium text-gray-700 mb-1">
                  GST Number
                </label>
                <input
                  id="gstInput"
                  type="text"
                  required
                  value={gstInput}
                  onChange={(e) => setGstInput(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${gstError ? 'border-red-500' : 'border-gray-300'
                    }`}
                  placeholder="Enter your GST number"
                />
                {gstError && (
                  <p className="mt-1 text-sm text-red-600">{gstError}</p>
                )}
              </div>
            </div>
            <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-2">
              <button
                onClick={() => setIsGstDialogOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleGstDialogSubmit}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Fetch GST Data
              </button>
            </div>
          </div>
        </div>
      )}


      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ProfileItem icon={<EmailOutlined />} label="Email" value={userData.email} />
        <ProfileItem icon={<BusinessOutlined />} label="Company" value={userData.companyName} />
        <ProfileItem icon={<PhoneOutlined />} label="Mobile" value={userData.mobileNo} />
        <ProfileItem icon={<ReceiptOutlined />} label="GST Number" value={userData.gstNo} />
        <ProfileItem icon={<ReceiptOutlined />} label="Pincode" value={userData.pincode} />
        <ProfileItem icon={<CalendarToday />} label="GST Registration Date" value={userData.rgdt} />
        {/* <ProfileItem className="col-span-12" icon={} label="" value={} /> */}

      </div>
      <div className="flex items-center p-4 bg-gray-50 rounded-lg col-span-12">
        <div className="text-blue-500 mr-4">
          <LocationOnOutlined />
        </div>
        <div>
          <p className="text-sm text-gray-600">Address</p>
          <p className="font-medium text-gray-800">{userData.shippingDetails}</p>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

function ProfileItem({ icon, label, value }) {
  return (
    <div className="flex items-center p-4 bg-gray-50 rounded-lg">
      <div className="text-blue-500 mr-4">{icon}</div>
      <div>
        <p className="text-sm text-gray-600">{label}</p>
        <p className="font-medium text-gray-800">{value}</p>
      </div>
    </div>
  );
}