import React, { useState } from "react";
import { CloseRounded } from "@mui/icons-material";

import ComplexNavbar from "./Navbar.jsx";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, Outlet } from "react-router-dom";
import { verifyToken } from "../../services/loginActions.js";
import { selectSellerEmail } from "../../services/loginReducer";
import { selectIsSellerLoggedIn } from "../../services/loginReducer";
import { logout } from "../../services/loginReducer";
import Sidebar from "./Sidebar.jsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryIcon from "@mui/icons-material/History";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
// import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ListIcon from "@mui/icons-material/List";
import logo from "../assets/Logo.png";
import { useEffectOnce } from "../customeHook/hook.js";
import { Dashboard } from "@mui/icons-material";

import { useLocation } from "react-router-dom";
export default function Home() {
  const navigation = useLocation();
  const email = useSelector(selectSellerEmail);
  const isLoggedIn = useSelector(selectIsSellerLoggedIn);
  const dispatch = useDispatch();

  // const [activeComponent, setActiveComponent] = useState("Profile");
  const [showSidebar, setShowSidebar] = useState(false);

  // const handleComponentClick = (componentName) => {
  //   setActiveComponent(componentName);
  // };
  const handleLogoutClick = (userType) => {
    dispatch(logout(userType));
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffectOnce(() => {
    // Dispatch action to verify token and retrieve email on app start
    dispatch(verifyToken("sellerToken"));
  });

  const componentArray = [
    {
      name: "Dashboard",
      path: ".",
      icon: <Dashboard />,
    },
    {
      name: "My Inquiry",
      path: "quotation",
      icon: <LocalOfferIcon />,
    },
    {
      name: "negotiation",
      path: "negotiation",
      icon: <LocalOfferIcon />,
    },
    
    {
      name: "Profile",
      path: "profile",
      icon: <AccountCircleIcon />,
    },
    {
      name: "Product List",
      path: "product-list",
      icon: <ListIcon />,
    },

    // {
    //   name: "History",
    //   path: "history",
    //   icon: <HistoryIcon />,
    // },
    {
      name: "Update Profile",
      path: "update-profile",
      icon: <AccountCircleIcon />,
    },
    
  ];

  if(!email && !isLoggedIn){
    handleLogoutClick();
  }

  return (
    <>
      <div className="flex fixed w-full ">
        <div
          className={`w-64 lg:w-72  ${showSidebar ? "block" : "hidden"
            } h-screen lg:block   bg-[#fff] shadow-2xl   absolute lg:relative top-0 left-0  z-50 transition-all duration-300 ease-in-out`}
        >
          <div className=" h-full">
            <div className="flex justify-end px-4 py-2 lg:hidden">
              <button aria-label="close" onClick={() => setShowSidebar(false)}>
                <CloseRounded className="w-6 h-6 text-gray-500" />
              </button>
            </div>
            <div className="  h-16 bg-blue-700  flex items-center justify-center border-b-[1px]">
              <Link to={"./"}>
                <LazyLoadImage
               title="sabecho logo"
                  src={logo}
                  loading="lazy"
                  width={160}
                  height={40}
                  className="shadow-2xl w-28 sm:w-32 md:w-40"
                  alt="Logo"
                  effect="blur"
                />
              </Link>
            </div>
            <Sidebar
              componentArray={componentArray}
              // handleComponentClick={handleComponentClick}
              handleLogoutClick={() => handleLogoutClick("seller")}
            />
          </div>
        </div>
        <div className="flex-1 grid grid-cols-1">
          <ComplexNavbar toggleSidebar={toggleSidebar} />
          <div className="flex-1 grid grid-cols-1 pb-12  h-screen p-3 md:p-4  overflow-y-scroll thin   will-change-scroll   ">
            <Outlet />
          </div>
        </div>
      </div>

      {!isLoggedIn && !email && <Navigate to="/seller" replace={true} />}
    </>
  );
}
