import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardContent, CardFooter } from "../../../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { AlertCircle, Pencil, Trash2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";

const API_URL = '/api/v1/explore-categories'; // Adjust as needed

export default function ExploreCategory() {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    productNames: '',
    metaDescription: '',
    keywords: '',
    image: null,
    altText: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(API_URL);
      setCategories(response.data);
    } catch (error) {
      setError('Failed to fetch categories');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'productNames' || key === 'keywords') {
        formDataToSend.append(key, JSON.stringify(formData[key].split(',')));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      if (isEditing) {
        await axios.put(`${API_URL}/${editId}`, formDataToSend);
      } else {
        await axios.post(API_URL, formDataToSend);
      }
      fetchCategories();
      resetForm();
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleEdit = (category) => {
    setFormData({
      title: category.title,
      slug: category.slug,
      productNames: category.productNames.join(','),
      metaDescription: category.metaDescription,
      keywords: category.keywords.join(','),
      altText: category.image.altText
    });
    setIsEditing(true);
    setEditId(category._id);
    setIsDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchCategories();
    } catch (error) {
      setError('Failed to delete category');
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      slug: '',
      productNames: '',
      metaDescription: '',
      keywords: '',
      image: null,
      altText: ''
    });
    setIsEditing(false);
    setEditId(null);
    setIsDialogOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Explore Categories</h1>
      
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button onClick={() => { resetForm(); setIsDialogOpen(true); }}>
            Add New Category
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{isEditing ? 'Edit Category' : 'Add New Category'}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Label htmlFor="title">Title</Label>
              <Input id="title" name="title" value={formData.title} onChange={handleInputChange} required />
            </div>
            <div>
              <Label htmlFor="slug">Slug (optional)</Label>
              <Input id="slug" name="slug" value={formData.slug} onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="productNames">Product Names (comma-separated)</Label>
              <Input id="productNames" name="productNames" value={formData.productNames} onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="metaDescription">Meta Description</Label>
              <Input id="metaDescription" name="metaDescription" value={formData.metaDescription} onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="keywords">Keywords (comma-separated)</Label>
              <Input id="keywords" name="keywords" value={formData.keywords} onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="image">Image</Label>
              <Input id="image" name="image" type="file" onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="altText">Alt Text</Label>
              <Input id="altText" name="altText" value={formData.altText} onChange={handleInputChange} />
            </div>
            <Button type="submit">{isEditing ? 'Update' : 'Create'}</Button>
          </form>
        </DialogContent>
      </Dialog>

      <Card className="mt-4">
        <CardHeader>
          <h2 className="text-xl font-semibold">Categories</h2>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Title</TableHead>
                <TableHead>Slug</TableHead>
                <TableHead>Products</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category._id}>
                  <TableCell>{category.title}</TableCell>
                  <TableCell>{category.slug}</TableCell>
                  <TableCell>{category.productNames.join(', ')}</TableCell>
                  <TableCell>
                    <Button variant="outline" size="icon" onClick={() => handleEdit(category)} className="mr-2">
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <Button variant="outline" size="icon" onClick={() => handleDelete(category._id)}>
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}