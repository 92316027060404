import React from "react";

export default function Pending({
  detail,
  handleViewDetails,
  getStatusClass,
  selectedTab,
}) {
  return (
    <div
      key={detail._id}
      className="p-4 border rounded-md shadow-md flex justify-between items-center mb-4"
    >
      <div className=" flex-3">
        <div className=" flex font-bold">
          <div>ID: {detail.reqId} </div>
          <div
            className={`relative ml-5 grid select-none self-start whitespace-nowrap rounded-lg py-1.5 px-3 font-sans text-xs font-bold uppercase ${getStatusClass(
              detail.status
            )}`}
          >
            {detail.status}
          </div>
        </div>
        <div>Item: {detail.name}</div>
        <div>Created At: {new Date(detail.createdAt).toLocaleString()}</div>
        <div>
          Qty: {detail.minQty || 0} {detail.measurement || "N/A"}
        </div>
      </div>
    </div>
  );
}
