import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// import { io } from "socket.io-client";

import NotificationSound from "./notification-sound.mp3"; // Import your notification sound

// const socket = io(""); // Adjust the URL if needed

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const audioPlayer = useRef(null); // Reference to the audio element

  // useEffect(() => {
  //   if ("Notification" in window && Notification.permission !== "granted") {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         fetchNotifications();
  //       }
  //     });
  //   } else {
  //     fetchNotifications();
  //   }

  //   // Listen for notification events
  //   socket.on("notification", (notification) => {
  //     setNotifications((prevNotifications) => [
  //       notification,
  //       ...prevNotifications,
  //     ]);
  //     setSnackbarMessage(notification.message); // Set message for Snackbar
  //     setOpenSnackbar(true); // Open Snackbar when notification is received
  //     playAudio(); // Play notification sound
  //     showBrowserNotification(notification.message); // Show browser notification
  //   });

  //   // Listen for notification_deleted events
  //   socket.on("notification_deleted", (deletedNotification) => {
  //     setNotifications((prevNotifications) =>
  //       prevNotifications.filter((notif) => notif.id !== deletedNotification.id)
  //     );
  //   });

  //   // Clean up on unmount
  //   return () => {
  //     socket.off("notification");
  //     socket.off("notification_deleted");
  //   };
  // }, []);

  const fetchNotifications = async () => {
    const response = await axios.get("/api/v1/notifications");
    setNotifications(response.data);
  };

  const deleteNotification = async (id) => {
    await axios.delete(`/api/v1/notifications/${id}`);
    fetchNotifications(); // Refresh the notifications list after deletion
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const playAudio = () => {
    audioPlayer.current.play();
  };

  const showBrowserNotification = (message) => {
    if ("Notification" in window && Notification.permission === "granted") {
      new Notification("New Notification", {
        body: message,
        icon: "/path/to/your/icon.png", // Optional: add an icon for the notification
      });
    }
  };

  return (
    <div className="notifications">
      {notifications.map((notif) => (
        <div
          className="max-w-xs bg-yellow-500 text-sm text-white rounded-xl shadow-lg"
          role="alert"
        >
          <div className="flex p-4">
            {notif.message}

            <div className="ms-auto">
              <button
              onClick={()=>deleteNotification(notif.id)}
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center size-5 rounded-lg text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      ))}
      <audio ref={audioPlayer} src={NotificationSound} />
    </div>
  );
};

export default Notifications;
