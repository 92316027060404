import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import { X, Search, ChevronUp, ChevronDown, Package, User, Mail, Phone, Briefcase, Hash, MapPin } from 'lucide-react';
import { decryptData } from '../../utils/decryption';
import { debounce } from 'lodash';
const Request = ({ setShowPopover, product, email, setAlert, setProducts, addAdminRequirements }) => {
  const [productData, setProductData] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [userData, setUserData] = useState(null);
  
  const [userDataFetched, setUserDataFetched] = useState(false);

  const fetchData = useCallback(debounce(async (productName) => {
    try {
      const response = await axios.get(`/api/v1/requirements/search/${productName}`);
      setProductData(response.data);
      setErrorMessage('');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage('Seller not available. They are currently not selling that product.');
      } else {
        console.error('Error fetching product data:', error);
      }
      setProductData([]);
    }
  }, 300), []);
  useEffect(() => {
    fetchData(product.name);
  }, [fetchData, product.name]);

  useEffect(() => {
    async function fetchUserData() {
      if (!userDataFetched) {
        try {
          const response = await fetch(`/api/v1/profile/${product.reqId}`);
          const data = await response.json();
          if (data.encryptedData) {
            const decryptedData = decryptData(data.encryptedData);
            setUserData(decryptedData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setUserDataFetched(true);
        }
      }
    }
    fetchUserData();
  }, [product.reqId, userDataFetched]);

  const handleCheckboxChange = (email) => {
    setSelectedCompanies(prev =>
      prev.includes(email) ? prev.filter(e => e !== email) : [...prev, email]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/v1/submit-quotation', {
        selectedCompanies,
        productName: product.name,
        averageQty: product.minQty,
        measurement: product.measurement || '',
        reqId: product?.reqId || '',
        requirementId: productData[0]._id,
        pid: product.pid,
        specification: product.specification,
      });

      await axios.put(`/api/v1/update-requirement/${product._id}`, { status: 'Accepted' });
      setAlert({ type: 'success', message: 'Enquiry successfully sent to the seller!' });
      addAdminRequirements({ email, status: 'Pending' }).then(setProducts);
      setShowPopover(false);
    } catch (error) {
      console.error('Error submitting quotation:', error);
      setAlert({ type: 'error', message: 'Failed to send enquiry. Please try again.' });
    }
  };

  const filteredData = productData.filter(item =>
    item.assignedSellers.some(seller =>
      seller.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      seller.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      seller.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) ||
    item.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = React.useMemo(() => {
    let sortableItems = [...filteredData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (['name', 'email', 'companyName'].includes(sortConfig.key)) {
          const aValue = a.assignedSellers[0][sortConfig.key];
          const bValue = b.assignedSellers[0][sortConfig.key];
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [filteredData, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl max-w-6xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white z-10 px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800">Request Quotation</h2>
          <button onClick={() => setShowPopover(false)} className="text-gray-600 hover:text-gray-800">
            <X size={24} />
          </button>
        </div>

        <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-4 bg-gray-50 border-b border-gray-200">
              <div className="flex items-center space-x-3 mb-2">
                <Package className="text-blue-500" size={24} />
                <h3 className="font-semibold text-xl text-gray-800">{product.name}</h3>
              </div>
              <p className="text-gray-600">Quantity: {product.minQty}</p>
            </div>
            {userData ? (
              <div className="p-4 space-y-3">
                <h4 className="font-semibold text-lg text-gray-700 mb-2">User Information</h4>
                <InfoRow icon={User} label="Name" value={userData.name} />
                <InfoRow icon={Mail} label="Email" value={userData.email} />
                <InfoRow icon={Phone} label="Mobile" value={userData.mobileNo} />
                <InfoRow icon={Briefcase} label="Company" value={userData.companyName} />
                <InfoRow icon={Hash} label="GST" value={userData.gstNo} />
                <InfoRow icon={MapPin} label="Pincode" value={userData.pincode} />
              </div>
            ) :
              <div className='flex justify-center items-center mt-2'>
                <h4 className="font-semibold text-lg text-gray-700 mb-2">User Not Login</h4>
              </div>


            }
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 col-span-2">
            <div className="flex items-center bg-gray-100 rounded-lg p-2">
              <Search className="text-gray-400 mr-2" size={20} />
              <input
                type="text"
                placeholder="Search by seller name, email, company or location"
                className="bg-transparent border-none focus:outline-none flex-grow"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {errorMessage ? (
              <div className="text-center text-red-500 my-4">{errorMessage}</div>
            ) : sortedData.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {['', 'ID', 'Name', 'Company Name', 'Email', 'Location', 'Mobile',].map((header) => (
                        <th
                          key={header}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          onClick={() => requestSort(header.toLowerCase())}
                        >
                          <div className="flex items-center">
                            {header}
                            {sortConfig.key === header.toLowerCase() && (
                              sortConfig.direction === 'ascending' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedData.flatMap((item) =>
                      item.assignedSellers.map((seller) => (
                        <tr key={`${item._id}-${seller.userId}`} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                              onChange={() => handleCheckboxChange(seller.email)}
                              checked={selectedCompanies.includes(seller.email)}
                            />
                          </td>
                          <TableCell>{seller.userId}</TableCell>
                          <TableCell>{seller.name}</TableCell>
                          <TableCell>{seller.companyName}</TableCell>
                          <TableCell>{seller.email}</TableCell>
                          <TableCell>{item.location}</TableCell>
                          <TableCell>{seller.mobileNo}</TableCell>

                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-gray-500 my-4">Loading...</div>
            )}

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
              >
                Send Quotation
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const InfoRow = ({ icon: Icon, label, value }) => (
  <div className="flex items-center space-x-3">
    <Icon className="text-gray-400" size={20} />
    <span className="text-gray-700 font-medium">{label}:</span>
    <span className="text-gray-600">{value}</span>
  </div>
);

const TableCell = ({ children }) => (
  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{children}</td>
);

export default Request;