import { useState, useEffect, useRef } from 'react';

export const useEffectOnce = (effect) => {
    const destroyFunc = useRef();
    const effectCalled = useRef(false);
    const renderAfterCalled = useRef(false);
    const [val, setVal] = useState(0);

    // If effect has been called previously, set renderAfterCalled to true
    if (effectCalled.current) {
        renderAfterCalled.current = true;
    }

    useEffect(() => {
        // Only execute the effect the first time around
        if (!effectCalled.current) {
            destroyFunc.current = effect();
            effectCalled.current = true;
        }

        // This forces one render after the effect is run
        setVal(val => val + 1);

        return () => {
            // If the component didn't render since the useEffect was called,
            // we know it's the dummy React cycle
            if (!renderAfterCalled.current) {
                return;
            }
            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
    }, []);
};
