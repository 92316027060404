import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGetAdminCustomerNegotationsQuery } from '../../services/services';

export default function Negotiation() {
  const { data: negotiations, isLoading, isError, refetch } = useGetAdminCustomerNegotationsQuery();
  const [selectedNegotiation, setSelectedNegotiation] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [commissionRates, setCommissionRates] = useState({});

  useEffect(() => {
    if (negotiations) {
      const initialCommissions = negotiations.reduce((acc, neg) => {
        acc[neg._id] = parseFloat(neg.commission || 0);
        return acc;
      }, {});
      setCommissionRates(initialCommissions);
    }
  }, [negotiations]);

  const handleCommissionChange = (id, value) => {
    setCommissionRates(prev => ({
      ...prev,
      [id]: parseFloat(value) || 0
    }));
  };

  const handleSendToSeller = async (negotiation) => {
    try {
      await axios.get(`/api/v1/updateNegotiationStatus/${negotiation._id}/${commissionRates[negotiation._id]}`);
      refetch();
    } catch (error) {
      console.error('Error sending to seller:', error);
    }
  };

  const handleSendToCustomer = async (negotiation) => {
    try {
      const commission = commissionRates[negotiation._id];
      if (commission !== undefined) {
        await axios.get(`/api/v1/updateNegotiation/${negotiation._id}/${commission}`);
        refetch();
      } else {
        alert("Please enter a commission rate");
      }
    } catch (error) {
      console.error('Error sending to customer:', error);
    }
  };
  

  const calculatePrices = (negotiation) => {
    const originalPriceWithCommission = negotiation.negotiationDetails.previewAmount;
    const existingCommission = parseFloat(negotiation.commission) || 0;
    const originalPrice = originalPriceWithCommission / (1 + existingCommission / 100);
    const negotiationPercentage = negotiation.negotiationDetails.negotiationAmount;
    const negotiatedPrice = originalPrice * (1 - negotiationPercentage / 100);
    const newCommissionRate = commissionRates[negotiation._id] || 0;
    const newCommissionAmount = negotiatedPrice * (newCommissionRate / 100);
    const totalPrice = negotiatedPrice + newCommissionAmount;

    return {
      originalPrice,
      negotiationPercentage,
      negotiatedPrice,
      commissionRate: newCommissionRate,
      commissionAmount: newCommissionAmount,
      totalPrice,
      existingCommission
    };
  };

  if (isLoading) return <div className="p-4">Loading...</div>;
  if (isError) return <div className="p-4 text-red-500">Error loading negotiations</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Negotiations</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {negotiations?.map((neg) => {
          const {
            originalPrice,
            negotiationPercentage,
            negotiatedPrice,
            commissionRate,
            commissionAmount,
            totalPrice,
            existingCommission
          } = calculatePrices(neg);

          return (
            <div key={neg.negId} className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-4 py-2">
                <h2 className="text-xl font-semibold">{neg.productDetails.productName}</h2>
                <p className="text-sm text-gray-600">NEG ID: {neg.negId}</p>
              </div>
              <div className="p-4 space-y-2">
                <p>
                  <span className="font-semibold">Status:</span>
                  <span className={`ml-2 px-2 py-1 rounded-full text-xs ${
                    neg.status === 'admin_pending' ? 'bg-yellow-200 text-yellow-800' :
                    neg.status === 'approved' ? 'bg-green-200 text-green-800' :
                    'bg-red-200 text-red-800'
                  }`}>
                    {neg.status}
                  </span>
                </p>
                <p><span className="font-semibold">Original Price (without commission):</span> ₹{originalPrice.toFixed(2)}</p>
                <p><span className="font-semibold">Existing Commission:</span> {existingCommission.toFixed(2)}%</p>
                <p><span className="font-semibold">Negotiation Discount:</span> {negotiationPercentage.toFixed(2)}%</p>
                <p><span className="font-semibold">Negotiated Price:</span> ₹{negotiatedPrice.toFixed(2)}</p>
                <p><span className="font-semibold">Quantity:</span> {neg.negotiationDetails.negotiationQuantity}</p>

                <div className="flex items-center space-x-2">
                  <label className="font-semibold">New Commission Rate:</label>
                  <input
                    type="number"
                    onChange={(e) => handleCommissionChange(neg._id, e.target.value)}
                    value={commissionRate}
                    className="border rounded px-2 py-1 w-20"
                  />
                  <span>%</span>
                </div>

                <p><span className="font-semibold">New Commission Amount:</span> ₹{commissionAmount.toFixed(2)}</p>
                <p><span className="font-semibold">Total Price:</span> ₹{totalPrice.toFixed(2)}</p>

                <div className="mt-4 flex space-x-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-md text-sm transition duration-300"
                    disabled={neg.status === 'seller_responded'}
                    onClick={() => handleSendToSeller(neg)}
                  >
                    Send to Seller
                  </button>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-md text-sm transition duration-300"
                    onClick={() => handleSendToCustomer(neg)}
                  >
                    Send to Customer
                  </button>
                  <button
                    className="bg-purple-500 hover:bg-purple-600 text-white px-3 py-1 rounded-md text-sm transition duration-300"
                    onClick={() => {
                      setSelectedNegotiation(neg);
                      setShowDetailsModal(true);
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {showDetailsModal && selectedNegotiation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-gray-100 px-6 py-4 border-b">
              <h2 className="text-2xl font-bold">Negotiation Details</h2>
              <button
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                onClick={() => setShowDetailsModal(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Basic Information</h3>
                  <p><span className="font-medium">Negotiation ID:</span> {selectedNegotiation.negId}</p>
                  <p><span className="font-medium">Status:</span> {selectedNegotiation.status}</p>
                  <p><span className="font-medium">Seller Email:</span> {selectedNegotiation.sellerEmail}</p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2">Product Details</h3>
                  <p><span className="font-medium">Name:</span> {selectedNegotiation.productDetails.productName}</p>
                  <p><span className="font-medium">ID:</span> {selectedNegotiation.productDetails.productId}</p>
                  <p><span className="font-medium">HSN Code:</span> {selectedNegotiation.productDetails.hsnCode}</p>
                  <p><span className="font-medium">Measurement:</span> {selectedNegotiation.productDetails.measurement}</p>
                  <p><span className="font-medium">GST:</span> {selectedNegotiation.productDetails.gst}%</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Negotiation Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p><span className="font-medium">Negotiation Amount:</span> ₹{selectedNegotiation.negotiationDetails.negotiationAmount}</p>
                    <p><span className="font-medium">Negotiation Quantity:</span> {selectedNegotiation.negotiationDetails.negotiationQuantity}</p>
                  </div>
                  <div>
                    <p><span className="font-medium text-lg">New Amount:</span> ₹{selectedNegotiation.negotiationDetails.newAmount}</p>
                  </div>
                  <div>
                    <p><span className="font-medium">Preview Amount:</span> ₹{selectedNegotiation.negotiationDetails.previewAmount}</p>
                    <p><span className="font-medium">Preview Quantity:</span> {selectedNegotiation.negotiationDetails.previewQuantity}</p>
                  </div>
                </div>
                <p className="mt-2"><span className="font-medium">Comment:</span> {selectedNegotiation.negotiationDetails.comment}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Additional Info</h3>
                <p><span className="font-medium">Delivery Info:</span> {selectedNegotiation.additionalInfo.deliveryInfo}</p>
                <p><span className="font-medium">Description:</span> {selectedNegotiation.additionalInfo.description}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Comments</h3>
                <p><span className="font-medium">Customer:</span> {selectedNegotiation.comments.customer}</p>
                <p><span className="font-medium">Seller:</span> {selectedNegotiation.comments.seller}</p>
                <p><span className="font-medium">Admin:</span> {selectedNegotiation.comments.admin}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Commission</h3>
                <p><span className="font-medium">Commission Rate:</span> {selectedNegotiation.commission}%</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}