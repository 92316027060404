// services/tabSlice.js

import { createSlice } from "@reduxjs/toolkit";

const tabSlice = createSlice({
  name: "tabs",
  initialState: {
    mainTab: "Pending", // Initial value for the main tab
    sidebarTab: "profile", // Initial value for the sidebar tab
  },
  reducers: {
    setMainTab: (state, action) => {
      state.mainTab = action.payload;
    },
    setSidebarTab: (state, action) => {
      state.sidebarTab = action.payload;
    },
  },
});

export const { setMainTab, setSidebarTab } = tabSlice.actions;

export const selectMainTab = (state) => state.tabs.mainTab;
export const selectSidebarTab = (state) => state.tabs.sidebarTab;

export default tabSlice.reducer;
