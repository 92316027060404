import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const StatCard = ({ title, value, icon, color }) => (
  <div className={`bg-white p-4 rounded-lg shadow-md border-l-4 border-${color}-500`}>
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <span className={`text-${color}-500`}>{icon}</span>
    </div>
    <p className={`text-2xl font-bold text-${color}-500`}>{value}</p>
  </div>
);

export default function Dashboard() {
  const [countTableData, setCountTableData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/countTotal");
        setCountTableData(response.data);
      } catch (error) {
        console.error('Error fetching counttable data:', error);
      }
    };
    fetchData();
  }, []);

  const chartData = countTableData ? [
    { name: 'Users', value: countTableData.totalUsers },
    { name: 'Products', value: countTableData.totalProducts },
    { name: 'Categories', value: countTableData.totalCategories },
    { name: 'Quotations', value: countTableData.totalQuotations },
    { name: 'Requirements', value: countTableData.totalRequirements },
    { name: 'News', value: countTableData.totalNews },
  ] : [];

  return (
    <div className="container mx-auto p-2  sm:p-6 bg-gray-100 overflow-y-auto thin  ">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      {countTableData && (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <StatCard title="Total Users" value={countTableData.totalUsers} icon="👥" color="blue" />
          <StatCard title="Total Products" value={countTableData.totalProducts} icon="📦" color="green" />
          <StatCard title="Total Categories" value={countTableData.totalCategories} icon="📊" color="yellow" />
          <StatCard title="Total Quotations" value={countTableData.totalQuotations} icon="📄" color="red" />
          <StatCard title="Total Requirements" value={countTableData.totalRequirements} icon="❓" color="purple" />
          <StatCard title="Total News" value={countTableData.totalNews} icon="📰" color="pink" />
        </div>
      )}
      <div className="bg-white mt-6 p-4 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Overview</h2>
        <div className="w-full h-[300px]" >
          <ResponsiveContainer>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}