import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { XMarkIcon } from '@heroicons/react/20/solid';

const ALERT_CONFIGS = {
  info: {
    icon: (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
      </svg>
    ),
    className: "text-blue-800 bg-blue-100 dark:bg-blue-200 dark:text-blue-800",
  },
  error: {
    icon: (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
    ),
    className: "text-red-800 bg-red-100 dark:bg-red-200 dark:text-red-800",
  },
  success: {
    icon: (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
      </svg>
    ),
    className: "text-green-800 bg-green-100 dark:bg-green-200 dark:text-green-800",
  },
  warning: {
    icon: (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
    ),
    className: "text-yellow-800 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800",
  },
  dark: {
    icon: (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
      </svg>
    ),
    className: "text-gray-800 bg-gray-100 dark:bg-gray-200 dark:text-gray-800",
  },
};

const AlertMessage = ({ type, message, link, onClose, autoClose = 5000 }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(true);
    if (autoClose) {
      const timer = setTimeout(() => setIsVisible(false), autoClose);
      return () => clearTimeout(timer);
    }
  }, [message, autoClose]);

  if (!isVisible) return null;

  const { icon, className } = ALERT_CONFIGS[type] || ALERT_CONFIGS.info;

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  return (
    <div className={`fixed z-50 top-4 right-4 w-full max-w-xs animate-slideIn ${className}`} role="alert">
      <div className="flex p-4 rounded-lg shadow-lg">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3 flex-1">
          <p className="text-sm font-medium">{message}</p>
          {link && (
            <Link to={link.href} className="text-sm font-semibold underline hover:no-underline mt-2 inline-block">
              {link.text}
            </Link>
          )}
        </div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50"
          onClick={handleClose}
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

AlertMessage.propTypes = {
  type: PropTypes.oneOf(Object.keys(ALERT_CONFIGS)).isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func,
  autoClose: PropTypes.number,
};

AlertMessage.defaultProps = {
  type: "info",
  link: null,
  onClose: null,
  autoClose: 5000,
};

export default AlertMessage;