import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { login, selectIsAdminLoggedIn } from "../../services/loginReducer";
import { useDispatch, useSelector } from "react-redux";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const isLoggedIn = useSelector(selectIsAdminLoggedIn);
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGenerateOtp = () => {
    if (email) {
      axios
        .post("/api/v1/otp/generate", {
          email,
        })
        .then((response) => {
          setOtpGenerated(true);
        })
        .catch((error) => {
          console.error("Error generating OTP:", error);
        });
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && otp) {
      axios
        .post("/api/v1/otp/verify", {
          email,
          otp,
          userType: "admin",
        })
        .then((response) => {
          const token = response.data.token;
          dispatch(login({ email, token, userType: "admin" }));
          Cookies.set("adminToken", token);
        })
        .catch((error) => {
          // console.error('Error verifying OTP:', error);
          alert("Error verifying OTP. Please try again.", error);
        });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white shadow-md border border-gray-200 rounded-lg w-96 p-4 sm:p-6 lg:p-8">
        {isLoggedIn && <Navigate to="dashboard/manage-order" replace={true} />}
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="flex justify-between">
            <h3 className="text-xl font-medium text-gray-900">
              Sign in to our platform
            </h3>
          </div>
          <div>
            <label
              htmlFor="email"
              className="text-sm font-medium text-gray-900 block mb-2"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="name@company.com"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>

          {otpGenerated && (
            <div>
              <label
                htmlFor="otp"
                className="text-sm font-medium text-gray-900 block mb-2"
              >
                OTP
              </label>
              <input
                type="text"
                name="otp"
                id="otp"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOtpChange}
                required
              />
            </div>
          )}
          <button
          
            type="submit"
            className={`w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ${
              !otpGenerated && "cursor-not-allowed opacity-50"
            }`}
            disabled={!otpGenerated}
          >
            Login to your account
          </button>
          {!otpGenerated && (
            <button
              type="button"
              className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
              onClick={handleGenerateOtp}
            >
              Generate OTP
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
