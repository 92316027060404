import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// Register custom fonts
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  fontWeight: 300,
});

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
  fontWeight: 700,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
    fontFamily: "Roboto",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    borderBottom: "1 solid #333",
    paddingBottom: 10,
  },
  logo: {
    width: 120,
    height: 50,
  },
  companyInfo: {
    fontSize: 10,
    textAlign: "right",
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20,
    color: "#1a5f7a",
  },
  section: {
    margin: "10 0",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 5,
    color: "#1a5f7a",
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
    flexWrap: "nowrap",
  },
  label: {
    width: "30%",
    fontWeight: 700,
    fontSize: 14,
    paddingRight: 5,
  },
  value: {
    width: "70%",
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 14,
    color: "#666",
    borderTop: "1 solid #666",
    paddingTop: 10,
  },
});

// Dummy data for the company info
const companyInfo = {
  name: "Your Company Name",
  address: "123 Business Rd, Business City, BZ 56789",
  phone: "123-456-7890",
  email: "contact@company.com",
  logo: "https://via.placeholder.com/240x100.png?text=Your+Logo", // Placeholder logo URL
};

// Create Document Component
const MyDocument = ({ detail,price }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={companyInfo.logo} />
        <View style={styles.companyInfo}>
          <Text>{companyInfo.name}</Text>
          <Text>{companyInfo.address}</Text>
          <Text>Phone: {companyInfo.phone}</Text>
          <Text>Email: {companyInfo.email}</Text>
        </View>
      </View>

      <Text style={styles.title}>Quotation</Text>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Quotation Details</Text>
        <View style={styles.row}>
          <Text style={styles.label}>Quotation ID:</Text>
          <Text style={styles.value}>{detail.reqId}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.value}>
            {new Date(detail.created_at).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Status:</Text>
          <Text style={styles.value}>{detail.status}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Product Information</Text>
        <View style={styles.row}>
          <Text style={styles.label}>Product Name:</Text>
          <Text style={styles.value}>{detail.productName}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Description:</Text>
          <Text style={styles.value}>{detail.description}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Quantity:</Text>
          <Text style={styles.value}>{detail.minQty || 0}</Text>
        </View>
        <View style={styles.row}>
  <Text style={styles.label}>Unit Price:</Text>
  <Text style={styles.value}>
    {(price / detail.minQty).toFixed(2)}
  </Text>
</View>
<View style={styles.row}>
  <Text style={styles.label}>Total Amount (inc. GST):</Text>
  <Text style={styles.value}>
    {(price * (1 + detail.gstPercentage / 100)).toFixed(2)}
  </Text>
</View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Additional Information</Text>
        <View style={styles.row}>
          <Text style={styles.label}>GST %:</Text>
          <Text style={styles.value}>{detail.gstPercentage}%</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>HSN Code:</Text>
          <Text style={styles.value}>{detail.hsnCode || "N/A"}</Text>
        </View>
    
      </View>

      <View style={styles.footer}>
        <Text>Thank you for your business!</Text>
        <Text>This is a computer-generated document and does not require a signature.</Text>
      </View>
    </Page>
  </Document>
);

export default MyDocument;