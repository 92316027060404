import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Textarea } from "../../../components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardContent, CardTitle } from "../../../components/ui/card";
import { Label } from "../../../components/ui/label";

const WhyChoose = () => {
  const [whyChooses, setWhyChooses] = useState([]);
  const [formData, setFormData] = useState({
    userType: '',
    image: null,
    imageAlt: '',
    title: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    keywords: []
  });
  const [editingId, setEditingId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const userTypes = ['Buyer', 'seller']; // Add or modify user types as needed

  useEffect(() => {
    fetchWhyChooses();
  }, []);

  const fetchWhyChooses = async () => {
    try {
      const response = await axios.get('/api/v1/why-choose');
      setWhyChooses(response.data);
    } catch (error) {
      console.error('Error fetching why chooses:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value) => {
    setFormData({ ...formData, userType: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleKeywordsChange = (e) => {
    const keywords = e.target.value.split(',').map(keyword => keyword.trim());
    setFormData({ ...formData, keywords });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (key === 'keywords') {
        data.append(key, JSON.stringify(formData[key]));
      } else if (key === 'image' && formData[key]) {
        data.append(key, formData[key]);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      if (editingId) {
        await axios.put(`/api/v1/why-choose/${editingId}`, data);
      } else {
        await axios.post('/api/v1/why-choose', data);
      }
      fetchWhyChooses();
      resetForm();
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (whyChoose) => {
    setEditingId(whyChoose._id);
    setFormData({
      userType: whyChoose.userType,
      imageAlt: whyChoose.imageAlt,
      title: whyChoose.title,
      description: whyChoose.description,
      metaTitle: whyChoose.metaTitle,
      metaDescription: whyChoose.metaDescription,
      keywords: whyChoose.keywords
    });
    setIsDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/why-choose/${id}`);
      fetchWhyChooses();
    } catch (error) {
      console.error('Error deleting why choose:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      userType: '',
      image: null,
      imageAlt: '',
      title: '',
      description: '',
      metaTitle: '',
      metaDescription: '',
      keywords: []
    });
    setEditingId(null);
  };

  return (
    <div className="p-4">
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button>{editingId ? 'Edit Why Choose' : 'Create Why Choose'}</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{editingId ? 'Edit Why Choose' : 'Create Why Choose'}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="userType">User Type</Label>
              <Select onValueChange={handleSelectChange} value={formData.userType}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a user type" />
                </SelectTrigger>
                <SelectContent>
                  {userTypes.map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="image">Image</Label>
              <Input
                id="image"
                name="image"
                type="file"
                onChange={handleImageChange}
                required={!editingId}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="imageAlt">Image Alt Text</Label>
              <Input
                id="imageAlt"
                name="imageAlt"
                value={formData.imageAlt}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="title">Title</Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="metaTitle">Meta Title</Label>
              <Input
                id="metaTitle"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="metaDescription">Meta Description</Label>
              <Textarea
                id="metaDescription"
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="keywords">Keywords (comma-separated)</Label>
              <Input
                id="keywords"
                name="keywords"
                value={formData.keywords.join(', ')}
                onChange={handleKeywordsChange}
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button type="submit">{editingId ? 'Update' : 'Create'}</Button>
              {editingId && (
                <Button type="button" onClick={resetForm} variant="outline">
                  Cancel
                </Button>
              )}
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <h2 className="text-2xl font-bold mt-8 mb-4">Why Choose List</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {whyChooses.map((whyChoose) => (
          <Card key={whyChoose._id}>
            <CardHeader>
              <CardTitle>{whyChoose.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-2">{whyChoose.description}</p>
              <img title={whyChoose.imageAlt} loading='lazy' src={`/api/v1/explore-categories/image/${whyChoose.image}`} alt={whyChoose.imageAlt} className="w-full h-40 object-cover mb-2" />
              <div className="flex justify-end space-x-2">
                <Button onClick={() => handleEdit(whyChoose)} variant="outline">Edit</Button>
                <Button onClick={() => handleDelete(whyChoose._id)} variant="destructive">Delete</Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default WhyChoose;