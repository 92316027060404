import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import loginReducer from "../services/loginReducer.js";
import favoritesReducer from "../services/favoritesReducer.js";
import { api as jsonServerApi } from "../services/services.js";
import { userApi } from "../services/userApi.js";
import tabSlice from "../services/tabSlice.js";

export const store = configureStore({
  reducer: {
    [jsonServerApi.reducerPath]: jsonServerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,  // Add this line
    login: loginReducer,
    favorites: favoritesReducer,
    tabs: tabSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(jsonServerApi.middleware)
      .concat(userApi.middleware),
});

setupListeners(store.dispatch);