import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import { Textarea } from '../../../components/ui/textarea';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardContent, CardFooter ,CardTitle} from "../../../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { AlertCircle, Pencil, Trash2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";

import { Loader2, Upload } from "lucide-react";

const AboutUsForm = () => {
  const [formData, setFormData] = useState({
    headerImage: null,
    whoWeAre: {
      title: '',
      description: '',
      images: []
    },
    ourValues: {
      title: '',
      description: '',
      values: [{ icon: '', title: '' }]
    },
    ourJourney: {
      title: '',
      description: '',
      milestones: [{ icon: '', description: '', year: '' }]
    },
    awardsAndAchievements: {
      title: '',
      awards: [{ image: null, title: '' }]
    },
    navigationButtons: [{ label: '', link: '' }]
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchAboutUsData();
  }, []);

  const fetchAboutUsData = async () => {
    try {
      const response = await axios.get('/api/v1/about-us');
      setFormData(response.data);
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to fetch About Us data' });
    }
  };

  const handleInputChange = (e, section, index, field) => {
    const { name, value, files } = e.target;
    setFormData(prev => {
      if (section) {
        if (index !== undefined) {
          const newArray = [...prev[section][field]];
          newArray[index] = { ...newArray[index], [name]: files ? files[0] : value };
          return { ...prev, [section]: { ...prev[section], [field]: newArray } };
        }
        return { ...prev, [section]: { ...prev[section], [name]: value } };
      }
      return { ...prev, [name]: files ? files[0] : value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    const data = new FormData();
    data.append('data', JSON.stringify(formData));

    // Append files
    if (formData.headerImage) data.append('headerImage', formData.headerImage);
    formData.whoWeAre.images.forEach((image, index) => {
      if (image instanceof File) data.append(`whoWeAreImages`, image);
    });
    formData.awardsAndAchievements.awards.forEach((award, index) => {
      if (award.image instanceof File) data.append(`awardImages`, award.image);
    });

    try {
      const response = await axios.post('/api/v1/about-us', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage({ type: 'success', text: 'About Us data updated successfully' });
      setFormData(response.data);
    } catch (error) {
      setMessage({ type: 'error', text: error.response?.data?.message || 'An error occurred while updating About Us data' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="w-[800px] max-w-full">
      <CardHeader>
        <CardTitle>About Us Page</CardTitle>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div>
              <Label htmlFor="headerImage">Header Image</Label>
              <Input id="headerImage" type="file" onChange={(e) => handleInputChange(e)} name="headerImage" />
            </div>

            <div>
              <Label htmlFor="whoWeAreTitle">Who We Are - Title</Label>
              <Input id="whoWeAreTitle" value={formData.whoWeAre.title} onChange={(e) => handleInputChange(e, 'whoWeAre')} name="title" />
            </div>

            <div>
              <Label htmlFor="whoWeAreDescription">Who We Are - Description</Label>
              <Textarea id="whoWeAreDescription" value={formData.whoWeAre.description} onChange={(e) => handleInputChange(e, 'whoWeAre')} name="description" />
            </div>

            <div>
              <Label>Who We Are - Images</Label>
              <Input type="file" multiple onChange={(e) => {
                const files = Array.from(e.target.files);
                setFormData(prev => ({
                  ...prev,
                  whoWeAre: { ...prev.whoWeAre, images: files }
                }));
              }} />
            </div>

            {/* Add similar sections for Our Values, Our Journey, etc. */}

            <div>
              <Label htmlFor="awardsTitle">Awards & Achievements - Title</Label>
              <Input id="awardsTitle" value={formData.awardsAndAchievements.title} onChange={(e) => handleInputChange(e, 'awardsAndAchievements')} name="title" />
            </div>

            {formData.awardsAndAchievements.awards.map((award, index) => (
              <div key={index}>
                <Label>Award {index + 1}</Label>
                <Input type="file" onChange={(e) => handleInputChange(e, 'awardsAndAchievements', index, 'awards')} name="image" />
                <Input value={award.title} onChange={(e) => handleInputChange(e, 'awardsAndAchievements', index, 'awards')} name="title" placeholder="Award Title" />
              </div>
            ))}
            <Button type="button" onClick={() => setFormData(prev => ({
              ...prev,
              awardsAndAchievements: {
                ...prev.awardsAndAchievements,
                awards: [...prev.awardsAndAchievements.awards, { image: null, title: '' }]
              }
            }))}>
              Add Award
            </Button>
          </div>
        </CardContent>
        <CardFooter>
          <Button type="submit" disabled={loading}>
            {loading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Updating
              </>
            ) : (
              <>
                Update
                <Upload className="ml-2 h-4 w-4" />
              </>
            )}
          </Button>
        </CardFooter>
      </form>
      {message && (
        <Alert variant={message.type === 'error' ? 'destructive' : 'default'}>
          <AlertTitle>{message.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
          <AlertDescription>{message.text}</AlertDescription>
        </Alert>
      )}
    </Card>
  );
};

export default AboutUsForm;