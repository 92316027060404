import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectSellerEmail } from "../../services/loginReducer";
import { useSelector } from "react-redux";
import NotFound from "../utility/NotFound";

export default function Rejected() {
    const email = useSelector(selectSellerEmail);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios
            .post("/api/v1/quotation/match/rejected", {
                email: email,
            })
            .then((response) => {
                setProducts(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [email]); 

    return (
        <div>
            <div>
                <div>
                    <span className="text-2xl font-semibold">Rejected Products</span>
                </div>
                <div>
                    <p className="text-xs py-3 text-gray-500">
                        Below are the products that you have Not Intrested:
                    </p>
                </div>
                <div>
                    <div className="relative w-full">
                        <table className="w-full text-left table-auto min-w-max">
                            <thead className="mb-2">
                                <tr>
                                    <th className="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                                        <p className="block text-sm font-semibold leading-none text-blue-gray-900 opacity-70">
                                            Product Name
                                        </p>
                                    </th>
                                    <th className="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                                        <p className="block text-sm font-semibold leading-none text-blue-gray-900 opacity-70">
                                            Quantity
                                        </p>
                                    </th>
                                    <th className="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                                        <p className="block text-sm font-semibold leading-none text-blue-gray-900 opacity-70">
                                            Amount
                                        </p>
                                    </th>
                                    <th className="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                                        <p className="block text-sm font-semibold leading-none text-blue-gray-900 opacity-70">
                                            Description
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.length > 0 &&
                                    products.map((product, index) => (
                                        <tr key={index} className="bg-white my-2 shadow-2xl">
                                            <td className="p-4 border-b border-blue-gray-200">
                                                <p className="block text-sm font-normal leading-normal text-blue-gray-900">
                                                    {product.productName}
                                                </p>
                                            </td>
                                            <td className="p-4 border-b border-blue-gray-200">
                                                <p className="block text-sm font-normal leading-normal text-blue-gray-900">
                                                    {product.averageQty}
                                                </p>
                                            </td>
                                            <td className="p-4 border-b border-blue-gray-200">
                                                <p className="block text-sm font-normal leading-normal text-blue-gray-900">
                                                    {product.selectedCompanies[0].amount}
                                                </p>
                                            </td>
                                            <td className="p-4 border-b border-blue-gray-200">
                                                <p className="block text-sm font-normal leading-normal text-blue-gray-900">
                                                    {product.selectedCompanies[0].description}
                                                </p>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {products.length === 0 && <NotFound />}
                    </div>
                </div>
            </div>
        </div>
    );
}
