import React, { useState } from "react";
import FavoriteProductTable from "./FavoriteProductTable";

export default function MyFavorite() {
  const [activeTab, setActiveTab] = useState("products");

  const tabs = [
    { id: "products", label: "Products", icon: ProductIcon },
    { id: "categories", label: "Categories", icon: CategoryIcon },
  ];

  return (
    <div className=" mx-auto  py-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-2">My Favorites</h1>
        <p className="text-sm text-gray-600">Manage your favorite items</p>
      </div>

      <div className="bg-white rounded-lg shadow-sm mb-6">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex-1 py-3 px-4 text-sm font-medium text-center focus:outline-none transition-colors duration-200 ${
                activeTab === tab.id
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-blue-600"
              }`}
            >
              <tab.icon className="w-5 h-5 mx-auto mb-1" />
              {tab.label}
            </button>
          ))}
        </div>

        <div className="p-4  sm:h-screen sm:overflow-y-scroll thin ">
          {activeTab === "products" && <FavoriteProductTable />}
          {activeTab === "categories" && (
            <p className="text-gray-500 text-center py-4">
              This is the <em className="font-semibold text-gray-800">categories</em> tab content.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function ProductIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v8m-4-4h8" />
    </svg>
  );
}

function CategoryIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  );
}