import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line } from 'recharts';
import { ArrowUpRight, ArrowDownRight, Users, ShoppingCart, IndianRupee, Eye } from 'lucide-react';

const data = [
  { name: 'Jan', sales: 4000, requests: 2400 },
  { name: 'Feb', sales: 3000, requests: 1398 },
  { name: 'Mar', sales: 2000, requests: 9800 },
  { name: 'Apr', sales: 2780, requests: 3908 },
  { name: 'May', sales: 1890, requests: 4800 },
  { name: 'Jun', sales: 2390, requests: 3800 },
];

const Card = ({ children, className = '' }) => (
  <div className={`bg-white p-6 rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const StatCard = ({ title, value, change, icon: Icon }) => (
  <Card>
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <Icon className="h-5 w-5 text-gray-400" />
    </div>
    <div className="text-2xl font-bold">{value}</div>
    <p className={`text-xs ${change >= 0 ? 'text-green-500' : 'text-red-500'} flex items-center`}>
      {change >= 0 ? <ArrowUpRight className="h-3 w-3 mr-1" /> : <ArrowDownRight className="h-3 w-3 mr-1" />}
      {Math.abs(change)}% from last month
    </p>
  </Card>
);

export default function Dashboard() {
  return (
    <div className="sm:p-6 bg-gray-100 rounded-md">
      <h1 className="text-3xl font-bold mb-6">Seller Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard title="Total Sales" value="₹103,430" change={5.2} icon={IndianRupee} />
        <StatCard title="New Requests" value="3,462" change={-2.1} icon={ShoppingCart} />
        <StatCard title="Active Users" value="2,300" change={3.1} icon={Users} />
        <StatCard title="Page Views" value="12,456" change={8.2} icon={Eye} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <Card>
          <h3 className="text-lg font-semibold mb-4">Sales vs Requests</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#8884d8" name="Sales" />
                <Bar dataKey="requests" fill="#82ca9d" name="Requests" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card>
          <h3 className="text-lg font-semibold mb-4">Monthly Trend</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="sales" stroke="#8884d8" name="Sales" />
                <Line type="monotone" dataKey="requests" stroke="#82ca9d" name="Requests" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>

      <Card>
        <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
        <ul className="space-y-2">
          <li>New order received - #1234</li>
          <li>Product restocked - SKU: ABC123</li>
          <li>Customer inquiry - Order status for #5678</li>
          <li>Promotion started - Summer Sale 20% OFF</li>
        </ul>
      </Card>
    </div>
  );
}