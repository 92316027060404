import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../utility/Footer";

export default function AboutUs() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get('/api/v1/about-us');
        setAboutData(response.data);
      } catch (error) {
        console.error("Error fetching about us data:", error);
      }
    };

    fetchAboutData();
  }, []);

  if (!aboutData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className={`h-screen bg-cover bg-center`}
        style={{ backgroundImage: `url(/api/v1/explore-categories/image/${aboutData.headerImage})` }}
>
        {/* Header image is set as background */}
      </div>
      {/* <nav className="relative -mt-9">
        <div className="w-max relative rounded-2xl bg-white shadow-2xl mx-auto px-4 py-3 flex justify-center space-x-4">
          {aboutData.navigationButtons.map((button, index) => (
            <button
              key={index}
              className={`px-4 py-2 ${
                index === 0
                  ? "text-white bg-blue-600"
                  : "text-blue-600 bg-blue-100"
              } rounded-lg`}
            >
              {button.label || `Button ${index + 1}`}
            </button>
          ))}
        </div>
      </nav> */}

      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold text-blue-600 mb-6">{aboutData.whoWeAre.title}</h1>
          <p className="text-gray-700 text-lg mb-8">{aboutData.whoWeAre.description}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {aboutData.whoWeAre.images.map((image, index) => (
              <img
              title={image}
              loading="lazy"
                key={index}
                src={`/api/v1/explore-categories/image/${image}`}
                alt={`Who We Are ${index + 1}`}
                className="rounded-lg w-full h-64 object-cover"
              />
            ))}
          </div>
        </div>
      </section>
{/* 
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-blue-600 mb-6">{aboutData.ourValues.title}</h2>
          <p className="text-gray-700 text-lg mb-8">{aboutData.ourValues.description}</p>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            {aboutData.ourValues.values.map((value, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <img
                  src={`/api/v1/explore-categories/image/${value.icon || "https://via.placeholder.com/50"}`}
                  alt={value.title}
                  className="mx-auto mb-4 w-16 h-16 object-contain"
                />
                <h3 className="text-lg font-semibold">{value.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-blue-600 mb-6">{aboutData.ourJourney.title}</h2>
          <p className="text-gray-700 text-lg mb-8">{aboutData.ourJourney.description}</p>
          <div className="flex justify-center overflow-x-auto space-x-4">
            {aboutData.ourJourney.milestones.map((milestone, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 w-64">
                <img
                  src={`/api/v1/explore-categories/image/${milestone.icon || "https://via.placeholder.com/50"}`}
                  alt={`Milestone ${index + 1}`}
                  className="mx-auto mb-4 w-16 h-16 object-contain"
                />
                <p>{milestone.description}</p>
                <p className="text-blue-600 mt-2">{milestone.year}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-blue-600 mb-6">
            {aboutData.awardsAndAchievements.title}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {aboutData.awardsAndAchievements.awards.map((award, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <img
                title={award.title}
                loading="lazy"
                  src={`/api/v1/explore-categories/image/${award.image}`}
                  alt={award.title}
                  className="mx-auto mb-4 w-full h-48 object-cover rounded"
                />
                <h3 className="text-lg font-semibold">{award.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}