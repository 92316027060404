import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1/' }),
  endpoints: (builder) => ({
    getUserAll: builder.query({
      query: (type) => `users/${type}`,
    }),
    getProducts: builder.query({
      query: (type) => `products/polymers-and-packaging/premium-boxes`,
    }),
    updateUser: builder.mutation({
      query: (userData) => ({
        url: 'update-users',
        method: 'PUT',
        body: userData,
      }),
    }),
    fetchGstData: builder.mutation({
      query: (data) => ({
        url: 'fetch-gst-data',
        method: 'POST',
        body: {data},
      }),
    }),
    updateProduct:builder.mutation({
      query:(data) => ({
        url:`/products/${data.productId}/assignSellers`,
        method:'PUT',
        body: {data},
      })
    }),
  }),
});

export const {useGetUserAllQuery,
   useUpdateUserMutation,
   useGetProductsQuery,

   useUpdateProductMutation, useFetchGstDataMutation } = userApi;