import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyToken } from './services/loginActions';
import { useEffectOnce } from './component/customeHook/hook';
import ErrorBoundary from './components/common/ErrorBoundary.js';
import Loading from './components/common/Loading';
import { customerRoutes } from './routes/customerRoutes';
import { adminRoutes } from './routes/adminRoutes';
import { sellerRoutes } from './routes/sellerRoutes';
import './index.css';
const LayoutWithNavbar = lazy(() => import('./component/Layout'));
const LayoutWithoutNavbar = lazy(() => import('./component/LayoutWithoutNavbar'));
const Hero = lazy(() => import('./component/Hero'));
const Price = lazy(() => import('./component/search/Price'));
const CheckOut = lazy(() => import('./component/customer/Components/CheckOut'));
const ThankYou = lazy(() => import('./component/customer/Components/ThankYou'));
const PaymentFailed = lazy(() => import('./component/customer/Components/PaymentFailed'));
const CustomerNegociationForm = lazy(() => import('./component/customer/Components/CustomerNegociationForm'));
const SitemapViewer = lazy(() => import('./component/SitemapViewer'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Suspense fallback={<Loading />}><LayoutWithNavbar /></Suspense>,
    children: [
      { path: '/', element: <Suspense fallback={<Loading />}><Hero /></Suspense> },
      { path: '/:type/:subtype?/:product?/:city?', element: <Suspense fallback={<Loading />}><Price /></Suspense> },
      ...customerRoutes,
    ],
  },
  {
    path: '/checkout/:userId',
    element: <Suspense fallback={<Loading />}><CheckOut /></Suspense>,
  },
  {
    path: '/thankyou/:payid/:orderId',
    element: <Suspense fallback={<Loading />}><ThankYou /></Suspense>,
  },
  {
    path: '/payment-failed',
    element: <Suspense fallback={<Loading />}><PaymentFailed /></Suspense>,
  },
  {
    path: '/negotiation/:userId',
    element: <Suspense fallback={<Loading />}><CustomerNegociationForm /></Suspense>,
  },
  {
    path: 'admin',
    element: <Suspense fallback={<Loading />}><LayoutWithoutNavbar /></Suspense>,
    children: adminRoutes,
  },
  {
    path: 'seller',
    element: <Suspense fallback={<Loading />}><LayoutWithoutNavbar /></Suspense>,
    children: sellerRoutes,
  },
  {
    path: '/sitemap.xml',
    element: <Suspense fallback={<Loading />}><SitemapViewer /></Suspense>,
  },
]);

function App() {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(verifyToken('token'));
  });

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;