import React, { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Sidebar from "../seller/Sidebar";
import Navbar from "../seller/Navbar";

import { selectIsAdminLoggedIn, logout } from "../../services/loginReducer";
import Notifications from "../notification/Notification";
import CategoryIcon from "@mui/icons-material/Category";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";

export default function AdminHome() {
  const [showSidebar, setShowSidebar] = useState(false);
  const isLoggedIn = useSelector(selectIsAdminLoggedIn);
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    dispatch(logout("admin"));
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const componentArray = [
    {
      name: "Dashboard",
      path: "",
      icon: <SpaceDashboardIcon />,
    },
    {
      name: "Manage Enquires",
      path: "manage-order",
      icon: <PlaylistAddCheckIcon />,
    },
    {
      name: "Manage Categories",
      path: "manage-categories",
      icon: <CategoryIcon />,
    },
    {
      name: "Product List",
      path: "add-product",
      icon: <AddToPhotosIcon />,
    },
    {
      name: "User Management",
      path: "user-management",
      icon: <PersonIcon />,
    },
    {
      name: "Utilities",
      path: "utils",
      icon: <PersonIcon />,
    },
  ];

  if (!isLoggedIn) {
    return <Navigate to="/admin" replace={true} />;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar
        componentArray={componentArray}
        handleLogoutClick={handleLogoutClick}
        isOpen={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-1  overflow-y-auto thin  bg-gray-100">
          <div className="container mx-auto sm:px-6 py-4">
            <Outlet />
          </div>
        </main>
        <Notifications />
      </div>
    </div>
  );
}