// src/App.js
import React from "react";


import ProductLists from "../component/seller/ProductLists";
// Admin Components
import AdminLogin from "../component/admin/AdminLogin";
import AdminHome from "../component/admin/AdminHome";
import AdminDashboard from "../component/admin/Home";
// import ProductRequests from "../component/admin/ProductRequests";
import ManageOrder from "../component/admin/ManageOrder";
import ManageCategory from "../component/admin/ManageCategory";
import UserManagement from "../component/admin/UserManagement";
import AddCategory from "../component/product/AddCategory";
import Utils from "../component/admin/general/Utils";




export const adminRoutes = [
    { path: "", element: <AdminLogin /> },
    {
      path: "dashboard",
      element: <AdminHome />,
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "add-product", element: <ProductLists admin="admin" /> },
        { path: "manage-order", element: <ManageOrder /> },
        { path: "manage-categories", element: <ManageCategory /> },
        { path: "user-management", element: <UserManagement /> },
        { path: "add-product", element: <AddCategory /> },
        {path:'utils', element: <Utils/>}
      ],
    },
  ];