import React from "react";

const newsData = [
  {
    title: "Sabecho Steel Prices Soar",
    content: "Steel prices have increased by 20% in the last quarter.",
    link: "#",
  },
  {
    title: "Sabecho Aluminum Demand Rises",
    content: "Demand for aluminum is expected to grow by 15% next year.",
    link: "#",
  },
  {
    title: "Sabecho Copper Shortage Looms",
    content: "Copper supplies are dwindling, causing concern among manufacturers.",
    link: "#",
  },
];

export default function RawMaterialNews() {
  return (
    <>
      {newsData.map((news, index) => (
        <div key={index} className="flex flex-col bg-white border shadow-sm rounded-xl">
          <div className="bg-gray-100 border-b rounded-t-xl py-3 px-4 md:py-4 md:px-5">
            <p className="mt-1 text-sm text-gray-500">Raw Material News</p>
          </div>
          <div className="p-4 md:p-5">
            <h3 className="text-lg font-bold text-gray-800">{news.title}</h3>
            <p className="mt-2 text-gray-500">{news.content}</p>
            <a
              className="mt-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
              href={news.link}
            >
              Read more
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6"></path>
              </svg>
            </a>
          </div>
        </div>
      ))}
    </>
  );
}
