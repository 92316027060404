import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import ProductRequests from "./ProductRequests";
import PriceOffer from "./PriceOffer";
import Quoted from "./Quoted";
import AlertMessage from "../utility/AlertMessage";
import Negotiation from "./Negotiation.jsx";

const ManageOrder = () => {
  const [activeComponent, setActiveComponent] = useState("Enquiry");
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderStats, setOrderStats] = useState(null);

  const tabs = [
    { name: "Pending Enquiry", component: "Enquiry", icon: "📋" },
    { name: "Quote Available", component: "Interests", icon: "💰" },
    { name: "Seller Quotation", component: "Seller Quotation", icon: "📝" },
    { name: "Negotiation", component: "Negotiation", icon: "🤝" },
    { name: "Orders Completed", component: "Completed", icon: "✅" },
  ];

  const handleTabChange = (newValue) => {
    setActiveComponent(newValue);
  };

  useEffect(() => {
    // Simulating data loading
    setTimeout(() => {
      setOrderStats({
        pendingEnquiries: 15,
        quotesAvailable: 8,
        sellerQuotations: 12,
        negotiations: 5,
        completed: 20,
      });
      setLoading(false);
    }, 1500);
  }, []);

  const chartData = orderStats
    ? Object.entries(orderStats).map(([key, value]) => ({
        name: key,
        value,
      }))
    : [];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  return (
    <div className=" px-4 sm:px-6 bg-gray-100 min-h-screen customscrollbar">
      <h1 className="text-3xl font-bold mb-6">Enquire Management Dashboard</h1>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Order Statistics</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Order Distribution</h2>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div> */}

          <div className="bg-white rounded-lg shadow-md mb-6">
            <nav className="flex overflow-x-auto thin " aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.component}
                  className={`flex-shrink-0 inline-flex items-center px-4 py-2 border-b-2 font-medium text-sm focus:outline-none ${
                    activeComponent === tab.component
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                  onClick={() => handleTabChange(tab.component)}
                >
                  <span className="mr-2">{tab.icon}</span>
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>

          <div className="bg-white rounded-lg shadow-md  sm:p-6">
            {alert && <AlertMessage type={alert.type} message={alert.message} />}

            {activeComponent === "Enquiry" && <ProductRequests setAlert={setAlert} />}
            {activeComponent === "Interests" && <PriceOffer setAlert={setAlert} />}
            {activeComponent === "Seller Quotation" && <Quoted setAlert={setAlert} />}
            {activeComponent === "Negotiation" && <Negotiation />}
            {activeComponent === "Completed" && <div>Completed Orders Component</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default ManageOrder;