import React from 'react';
import { Link } from 'react-router-dom';
import { AccountCircle, Favorite, Timeline, Logout, Close } from '@mui/icons-material';

const Sidebar = ({ isOpen, onClose, componentArray, handleLogoutClick }) => {
  return (
    <>
      {/* Mobile overlay */}
      {isOpen && (
        <div
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 "
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`rounded-md 
          fixed md:sticky top-0 left-0  w-64 
          bg-blue-700 text-white
          transform transition-transform duration-300 ease-in-out z-30
          ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0
          pt-16 md:pt-0
        `}
      >
        <div className="flex flex-col h-full p-4 overflow-y-auto thin ">
          {/* Close button for mobile */}
          <button
            onClick={onClose}
            className="md:hidden absolute top-4 right-4 text-white hover:text-gray-300 transition-colors duration-200"
          >
            <Close fontSize="large" />
          </button>

          {/* Navigation */}
          <nav className="flex-grow   h-[60vh]">
            <ul className="space-y-2">
              {componentArray.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/dashboard/${item.path}`}
                    className="flex items-center p-3 rounded-lg hover:bg-blue-600 transition-colors duration-200"
                    onClick={onClose}
                  >
                    {item.icon}
                    <span className="ml-3">{item.name}</span>
                  </Link>
                </li>

              ))}
              <li>
                <button
                  onClick={handleLogoutClick}
                  className="mt-auto flex items-center p-3 rounded-lg hover:bg-purple-600 transition-colors duration-200"
                >
                  <Logout />
                  <span className="ml-3">Logout</span>
                </button>
              </li>
            </ul>
          </nav>

          {/* Logout button */}

        </div>
      </aside>
    </>
  );
};

export default Sidebar;