import React, { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  PersonAddAlt as PersonAddAltIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axiosInstance from "./axiosInstance";

// Main component
const ManageCategory = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CategoryTable />
    </QueryClientProvider>
  );
};

// Component to display the Category Table
const CategoryTable = () => {
  const queryClient = useQueryClient();
  const { data: categories = [], isLoading, isError } = useGetCategories();
  const [validationErrors, setValidationErrors] = useState({});
  const [editRowData, setEditRowData] = useState(null);

  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "Id", enableEditing: false, size: 80 },
      {
        accessorKey: "category",
        header: "Category",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
        },
        size: 100,
      },
      {
        accessorKey: "subCategory",
        header: "Sub Category",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
        },
        size: 100,
      },
      {
        accessorKey: "p_name",
        header: "Product Name",
        muiEditTextFieldProps: {
          required: true,
        },
      },
      {
        accessorKey: "location",
        header: "Location",
        muiEditTextFieldProps: {
          required: true,
        },
      },
      {
        accessorKey: "brand",
        header: "Brand",
        muiEditTextFieldProps: {
          required: true,
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    return (categories ?? []).map((category) => ({
      _id: category._id,
      id: category.id,
      category: category.category,
      subRows: (category?.subCategory ?? []).map((subCategory) => ({
        _id: subCategory._id,
        id: subCategory.id,
        subCategory: subCategory.name,
        categoryId: category._id,
        subRows: (subCategory?.product ?? []).map((product) => ({
          ...product,
          subCategoryId: subCategory._id,
          categoryId: category._id,
          subCategory: subCategory.name,
        })),
      })),
    }));
  }, [categories]);

  const createCategory = async (newCategory) => {
    try {
      await axiosInstance.post("/categories", newCategory);
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const createSubCategory = async (categoryId, subCategory) => {
    try {
      await axiosInstance.post(
        `/categories/${categoryId}/subcategories`,
        subCategory
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error creating subcategory:", error);
    }
  };

  const updateCategory = async (categoryId, updatedCategory) => {
    try {
      await axiosInstance.put(`/categories/${categoryId}`, updatedCategory);
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const updateSubCategory = async (
    categoryId,
    subCategoryId,
    updatedSubCategory
  ) => {
    try {
      await axiosInstance.put(
        `/categories/${categoryId}/subcategories/${subCategoryId}`,
        updatedSubCategory
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  const updateProduct = async (
    categoryId,
    subCategoryId,
    productId,
    updatedProduct
  ) => {
    try {
      await axiosInstance.put(
        `/categories/${categoryId}/subcategories/${subCategoryId}/products/${productId}`,
        updatedProduct
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      await axiosInstance.delete(`/categories/${categoryId}`);
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const createProduct = async (categoryId, subCategoryId, product) => {
    try {
      await axiosInstance.post(
        `/categories/${categoryId}/subcategories/${subCategoryId}/products`,
        product
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const deleteSubCategory = async (categoryId, subCategoryId) => {
    try {
      await axiosInstance.delete(
        `/categories/${categoryId}/subcategories/${subCategoryId}`
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const deleteProduct = async (categoryId, subCategoryId, productId) => {
    try {
      await axiosInstance.delete(
        `/categories/${categoryId}/subcategories/${subCategoryId}/products/${productId}`
      );
      queryClient.invalidateQueries("categories");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleCreateCategory = () => {
    const newCategory = { category: "New Category", subCategory: [] };
    createCategory(newCategory);
  };

  const handleCreateSubCategory = (categoryId) => {
    const newSubCategory = { name: "New Subcategory", product: [] };
    createSubCategory(categoryId, newSubCategory);
  };

  const handleCreateProduct = (categoryId, subCategoryId) => {
    const newProduct = {
      p_name: "New Product",
      location: "",
      city: "",
      brand: "",
    };
    createProduct(categoryId, subCategoryId, newProduct);
  };

  const handleUpdateCategory = (categoryId, updatedCategory) => {
    updateCategory(categoryId, updatedCategory);
  };

  const handleUpdateSubCategory = (
    categoryId,
    subCategoryId,
    updatedSubCategory
  ) => {
    updateSubCategory(categoryId, subCategoryId, updatedSubCategory);
  };

  const handleUpdateProduct = (
    categoryId,
    subCategoryId,
    productId,
    updatedProduct
  ) => {
    updateProduct(categoryId, subCategoryId, productId, updatedProduct);
  };

  const handleDeleteCategory = (categoryId) => {
    deleteCategory(categoryId);
  };

  const handleDeleteSubCategory = (categoryId, subCategoryId) => {
    deleteSubCategory(categoryId, subCategoryId);
  };

  const handleDeleteProduct = (categoryId, subCategoryId, productId) => {
    deleteProduct(categoryId, subCategoryId, productId);
  };

  const handleEditRow = (row) => {
    setEditRowData(row.original);
  };

  const handleSaveEdit = async () => {
    if (editRowData._id) {
      // Update
      if (editRowData.category) {
        await handleUpdateCategory(editRowData._id, {
          category: editRowData.category,
        });
      } else if (editRowData.subCategory) {
        await handleUpdateSubCategory(editRowData.categoryId, editRowData._id, {
          name: editRowData.subCategory,
        });
      } else {
        await handleUpdateProduct(
          editRowData.categoryId,
          editRowData.subCategoryId,
          editRowData._id,
          {
            p_name: editRowData.p_name,
            location: editRowData.location,
            brand: editRowData.brand,
          }
        );
      }
    } else {
      // Create
      await createCategory({
        category: editRowData.category,
      });
    }
    setEditRowData(null);
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableEditing={true}
        createDisplayMode="modal"
        editDisplayMode="modal"
        enableExpanding
        getRowId={(row) => row.id}
        onCreatingRowSave={handleCreateCategory}
        onEditingRowSave={handleSaveEdit}
        initialState={{
          columnPinning: { left: ["actions"], right: [] },
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        state={{ isLoading, showAlertBanner: isError }}
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditRow(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => handleDeleteCategory(row.original._id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Subordinate">
              <IconButton
                onClick={() => handleCreateSubCategory(row.original._id)}
              >
                <PersonAddAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button

            startIcon={<PersonAddAltIcon />}
            variant="contained"
            onClick={handleCreateCategory}
          >
            Create New Category
          </Button>
        )}
      />
      <Dialog open={!!editRowData} onClose={() => setEditRowData(null)}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
          {editRowData && (
            <>
              {editRowData.category !== undefined && (
                <TextField
                  label="Category"
                  value={editRowData.category}
                  onChange={(e) =>
                    setEditRowData({ ...editRowData, category: e.target.value })
                  }
                  fullWidth
                />
              )}
              {editRowData.subCategory !== undefined && (
                <TextField
                  label="Sub Category"
                  value={editRowData.subCategory}
                  onChange={(e) =>
                    setEditRowData({
                      ...editRowData,
                      subCategory: e.target.value,
                    })
                  }
                  fullWidth
                />
              )}
              {editRowData.p_name !== undefined && (
                <TextField
                  label="Product Name"
                  value={editRowData.p_name}
                  onChange={(e) =>
                    setEditRowData({ ...editRowData, p_name: e.target.value })
                  }
                  fullWidth
                />
              )}
              {editRowData.location !== undefined && (
                <TextField
                  label="Location"
                  value={editRowData.location}
                  onChange={(e) =>
                    setEditRowData({ ...editRowData, location: e.target.value })
                  }
                  fullWidth
                />
              )}
              {editRowData.brand !== undefined && (
                <TextField
                  label="Brand"
                  value={editRowData.brand}
                  onChange={(e) =>
                    setEditRowData({ ...editRowData, brand: e.target.value })
                  }
                  fullWidth
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditRowData(null)}>Cancel</Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Hook to get categories from API
function useGetCategories() {
  return useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const response = await axiosInstance.get("/categories/all");
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
}

const validateRequired = (value) => !!value.length;

export default ManageCategory;
