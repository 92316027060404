import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../../../services/loginReducer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PaymentInvoice from "./PaymentInvoice.jsx";
import { ChevronDown, ChevronUp, Download, Eye } from "lucide-react";

export default function CompletedOrders() {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedPayment, setExpandedPayment] = useState(null);
  const email = useSelector(selectUserEmail);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.post("/api/payments/paymentDetails", { email });
        setPaymentDetails(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };
    fetchPaymentDetails();
  }, [email]);

  const toggleExpand = (paymentId) => {
    setExpandedPayment(expandedPayment === paymentId ? null : paymentId);
  };

  if (loading) return <div className="p-4 text-center">Loading...</div>;
  if (error) return <div className="p-4 text-red-500 text-center">{error}</div>;
  if (paymentDetails.length === 0) return <div className="p-4 text-center">No payment details found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Completed Orders</h2>
      <div className="space-y-4">
        {paymentDetails.map((payment) => (
          <div key={payment._id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-4 sm:p-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">Payment ID: {payment.paymentId}</h3>
                  <p className="text-sm text-gray-600">Status: {payment.status}</p>
                </div>
                <div className="mt-2 sm:mt-0">
                  <span className="text-xl font-bold text-green-600">₹{payment.amount}</span>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                <PDFDownloadLink
                  document={<PaymentInvoice detail={payment} newAmount={payment.negotiationDetails?.newAmount} />}
                  fileName="PaymentInvoice.pdf"
                >
                  {({ loading }) => (
                    <button
                      className="flex items-center justify-center w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                      disabled={loading}
                    >
                      <Download className="w-4 h-4 mr-2" />
                      {loading ? "Loading..." : "Download Invoice"}
                    </button>
                  )}
                </PDFDownloadLink>
                <button
                  onClick={() => toggleExpand(payment._id)}
                  className="flex items-center justify-center w-full sm:w-auto px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                >
                  {expandedPayment === payment._id ? (
                    <>
                      <ChevronUp className="w-4 h-4 mr-2" />
                      Hide Details
                    </>
                  ) : (
                    <>
                      <ChevronDown className="w-4 h-4 mr-2" />
                      View Details
                    </>
                  )}
                </button>
              </div>
            </div>
            {expandedPayment === payment._id && (
              <div className="bg-gray-50 p-4 sm:p-6 border-t border-gray-200">
                <h4 className="font-semibold text-lg mb-3">Order Details</h4>
                {payment.orderDetails.map((order, index) => (
                  <div key={index} className="bg-white p-4 rounded-md shadow-sm mb-3 last:mb-0">
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h5 className="font-semibold text-gray-800">{order.productName}</h5>
                        <p className="text-sm text-gray-600">ReqId: {order.reqId}</p>
                      </div>
                      <span className="font-bold text-green-600">₹{order.negotiationDetails?.newAmount || order.amount}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-2 text-sm">
                      <p><span className="font-medium">Quantity:</span> {order.minQty}</p>
                      <p><span className="font-medium">Measurement:</span> {order.measurement}</p>
                      <p><span className="font-medium">Status:</span> {order.status}</p>
                    </div>
                    {order.description && (
                      <p className="mt-2 text-sm text-gray-700"><span className="font-medium">Description:</span> {order.description}</p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}