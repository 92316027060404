// src/App.js
import React from "react";


import ContactUs from "../component/utility/ContactUs";
import News from "../component/utility/News";
import AboutUs from "../component/news/AboutUs";
import Error from "../component/utility/Error";
// Customer Components
import ThankYou from "../component/customer/Components/ThankYou";
import Dashboard from "../component/customer/Dashboard";
import Tracking from "../component/customer/Components/Tracking";
import UserProfile from "../component/customer/Components/UserProfile";

import MyFavorite from "../component/customer/Components/MyFavorite";





export const customerRoutes = [
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        { path: "Tracking-list", element: <Tracking /> },
        { path: "Profile", element: <UserProfile /> },
        { path: "my-favorite", element: <MyFavorite /> },
      ],
    },
    { path: "contact", element: <ContactUs /> },
    { path: "news", element: <News /> },
    { path: "about", element: <AboutUs /> },
    { path: "*", element: <Error /> },
  ];