import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axiosInstance from "./axiosInstance"; // Import the configured Axios instance
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [file, setFile] = useState(null);

  // Read users
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();

  const columns = useMemo(
    () => [
      {
        accessorKey: "_id",
        header: "ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "name",
        header: "Name",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              name: undefined,
            })),
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        muiEditTextFieldProps: {
          type: "email",
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              email: undefined,
            })),
        },
      },
      {
        accessorKey: "companyName",
        header: "Company Name",
        muiEditTextFieldProps: {
          error: !!validationErrors?.companyName,
          helperText: validationErrors?.companyName,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              companyName: undefined,
            })),
        },
      },
      {
        accessorKey: "mobileNo",
        header: "Mobile Number",
        muiEditTextFieldProps: {
          error: !!validationErrors?.mobileNo,
          helperText: validationErrors?.mobileNo,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              mobileNo: undefined,
            })),
        },
      },
      {
        accessorKey: "gstNo",
        header: "GST Number",
        muiEditTextFieldProps: {
          error: !!validationErrors?.gstNo,
          helperText: validationErrors?.gstNo,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              gstNo: undefined,
            })),
        },
      },
      {
        accessorKey: "userType",
        header: "User Type",
        muiEditTextFieldProps: {
          error: !!validationErrors?.userType,
          helperText: validationErrors?.userType,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              userType: undefined,
            })),
        },
      },
      {
        accessorKey: "pincode",
        header: "Pincode",
        muiEditTextFieldProps: {
          error: !!validationErrors?.pincode,
          helperText: validationErrors?.pincode,
          onFocus: () =>
            setValidationErrors((prev) => ({
              ...prev,
              pincode: undefined,
            })),
        },
      },
    ],
    [fetchedUsers, validationErrors]
  );

  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null);
  };

  const handleSaveUser = async ({ values, table }) => {
    await updateUser(values);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(row.original._id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    enableRowNumbers: true,
    initialState: { density: "compact" },
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    getRowId: (row) => row._id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Create New User
      </Button>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  return <MaterialReactTable table={table} />;
};

// CREATE hook (post new user to API)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      const response = await axiosInstance.post(
        "/users",
        user
      );
      return response.data;
    },
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(["users"], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          _id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
  });
}

// READ hook (get users from API)
function useGetUsers() {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await axiosInstance.get("/users");
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
}

// UPDATE hook (put user in API)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      const response = await axiosInstance.put(`/users/${user._id}`, user);
      return response.data;
    },
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser._id === newUserInfo._id ? newUserInfo : prevUser
        )
      );
    },
  });
}

// DELETE hook (delete user in API)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      const response = await axiosInstance.delete(`/users/${userId}`);
      return response.data;
    },
    onMutate: (userId) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.filter((user) => user._id !== userId)
      );
    },
  });
}
const queryClient = new QueryClient();

const UserManagement = ({ userType }) => (
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default UserManagement;

const validateRequired = (value) => !!value;
const validateEmail = (email) =>
  !!email &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user) {
  return {
    email: !validateEmail(user.email) ? "Incorrect Email Format" : "",
    name: !validateRequired(user.name) ? "Name is Required" : "",
  };
}
