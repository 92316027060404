import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { selectSellerEmail } from "../../services/loginReducer";
import { useQuotationMatchMutation } from "../../services/services";
import AlertMessage from "../utility/AlertMessage";
import axios from "axios";
import NotFound from '../utility/NotFound';
import History from "./History";
import Rejected from "./Rejected";
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from '../../components/ui/alert';

const QuotationModal = ({ isOpen, onClose, quotation, onSubmit, onReject }) => {
  const [basicAmount, setBasicAmount] = useState('');
  const [description, setDescription] = useState('');

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(basicAmount, description);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">{quotation.productName}</h2>
        <Alert variant="info" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Enter only the basic amount. Transport and GST are additional.
          </AlertDescription>
        </Alert>
        <div className="space-y-3">
          <p><span className="font-semibold">Quantity:</span> {quotation.averageQty} {quotation.measurement}</p>
          <p><span className="font-semibold">Specification:</span> {quotation.specification}</p>
          <p><span className="font-semibold">HSN Code:</span> {quotation.productDetails.hsnCode}</p>
          <p><span className="font-semibold">GST %:</span> {quotation.productDetails.gstPercentage}%</p>
          <div>
            <label htmlFor="basicAmount" className="block text-sm font-medium text-gray-700">Basic Amount (₹)</label>
            <input
              type="number"
              id="basicAmount"
              required
              value={basicAmount}
              onChange={(e) => setBasicAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 py-2 outline-none border px-4"
              placeholder="Enter basic amount only"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="3"
              className="mt-1 border  outline-none px-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Additional details (optional)"
            ></textarea>
          </div>
        </div>
        <div className="mt-5 flex justify-end space-x-3">
          <button onClick={onReject} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Reject</button>
          <button onClick={handleSubmit} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Accept</button>
          <button onClick={onClose} className="px-4 py-2 bg-yellow-300 text-gray-800 rounded hover:bg-yellow-400">Close</button>
        </div>
      </div>
    </div>
  );
};


const QuotationTable = ({ fields, onView }) => {
  return (
    <div className="overflow-x-auto shadow-sm">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">REQID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specification</th>
            {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">HSN Code</th> */}
            {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">GST %</th> */}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="px-6 py-4 whitespace-nowrap">{field.reqId}</td>
              <td className="px-6 py-4 whitespace-nowrap">{field.pid}</td>
              <td className="px-6 py-4 whitespace-nowrap">{field.productName}</td>
              <td className="px-6 py-4 whitespace-nowrap">{field.averageQty} {field.measurement }</td>
              <td className="px-6 py-4 whitespace-nowrap">{field.specification}</td>
              {/* <td className="px-6 py-4 whitespace-nowrap">{field.productDetails.hsnCode}</td> */}
              {/* <td className="px-6 py-4 whitespace-nowrap">{field.productDetails.gstPercentage}%</td> */}
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => onView(index)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function Quotation() {
  const email = useSelector(selectSellerEmail);
  const [alert, setAlert] = useState(null);
  const [fetchQuotations] = useQuotationMatchMutation();
  const [activeTab, setActiveTab] = useState("pending");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);

  const methods = useForm();
  const { control, reset } = methods;
  const { fields, remove } = useFieldArray({
    control,
    name: "quotations",
  });

  useEffect(() => {
    if (email) {
      fetchQuotations({ email })
        .unwrap()
        .then((response) => {
          reset({ quotations: response });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [email, reset]);

  const onSubmit = async (index, amount, description) => {
    try {
      const formData = methods.getValues();
      const quotationData = {
        ...formData.quotations[index],
        email: email,
        status: 'accepted',
        amount: parseFloat(amount),
        description: description
      };

      await axios.post("/api/v1/updateQuotation", quotationData);

      setAlert({
        type: "success",
        message: "Quotation accepted successfully!",
      });

      remove(index);
      setModalOpen(false);

      setTimeout(() => setAlert(null), 3000);
    } catch (error) {
      console.error("Error submitting quotation:", error);
      setAlert({
        type: "error",
        message: "Failed to accept quotation. Please try again.",
      });
    }
  };


  const onReject = async (index) => {
    try {
      const formData = methods.getValues();
      const quotation = formData.quotations[index];
      
      // Find the index of the current seller in the selectedCompanies array
      const sellerIndex = quotation.selectedCompanies.findIndex(
        company => company.email === email
      );

      if (sellerIndex === -1) {
        throw new Error("Seller not found in the quotation");
      }

      // Create a new selectedCompanies array with the updated status
      const updatedSelectedCompanies = quotation.selectedCompanies.map((company, idx) => 
        idx === sellerIndex ? { ...company, status: 'rejected' } : company
      );

      const quotationData = {
        ...quotation,
        selectedCompanies: updatedSelectedCompanies,
        email: email,
      };

      await axios.post("/api/v1/updateQuotation", quotationData);

      setAlert({
        type: "success",
        message: "Quotation rejected successfully!",
      });

      remove(index);
      setModalOpen(false);

      setTimeout(() => setAlert(null), 3000);
    } catch (error) {
      console.error("Error rejecting quotation:", error);
      setAlert({
        type: "error",
        message: "Failed to reject quotation. Please try again.",
      });
    }
  };

  const handleView = (index) => {
    setSelectedQuotation(fields[index]);
    setModalOpen(true);
  };

  const tabs = [
    { value: "pending", label: "Pending", icon: "📋" },
    { value: "rejected", label: "Rejected", icon: "❌" },
    { value: "accepted", label: "Accepted", icon: "✅" },
    { value: "order", label: "Order", icon: "🛒" },
  ];

  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
  };

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "pending":
        return fields.length > 0 ? (
          <QuotationTable
            fields={fields}
            onView={handleView}
          />
        ) : (
          <NotFound title="Pending Quotation" />
        );
      case "rejected":
        return <Rejected/>;
      case "accepted":
        return <History/>;
      case "order":
        return <div>Order Component</div>;
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="container mx-auto px-4 py-8">
        {alert && <AlertMessage type={alert.type} message={alert.message} />}
        <h1 className="text-3xl font-bold mb-6">Quotations</h1>

        <div className="bg-white rounded-lg shadow-md mb-6">
          <nav className="flex overflow-x-auto thin" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.value}
                className={`flex-shrink-0 inline-flex items-center px-4 py-2 border-b-2 font-medium text-sm focus:outline-none ${activeTab === tab.value
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                onClick={() => handleTabChange(tab.value)}
              >
                <span className="mr-2">{tab.icon}</span>
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        {renderActiveComponent()}

        <QuotationModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          quotation={selectedQuotation}
          onSubmit={(amount, description) => onSubmit(fields.findIndex(f => f.id === selectedQuotation.id), amount, description)}
          onReject={() => onReject(fields.findIndex(f => f.id === selectedQuotation.id))}
        />
      </div>
    </FormProvider>
  );
}