import React, { useState } from "react";
import { MenuOutlined } from "@mui/icons-material";
import { NotificationAdd } from "@mui/icons-material";
const Navbar = ({ toggleSidebar }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="block w-full  px-4 py-3 mx-auto  bg-gradient-to-tr from-blue-gray-900 to-blue-gray-800">
      <div className="flex flex-wrap items-center justify-between gap-y-4">
        <div className="flex gap-1  md:mr-4">
          <button
          title="menu"
            className="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            aria-label="menu"
            type="button"
          >
            <span
              className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              onClick={toggleSidebar}
            >
              <MenuOutlined className="w-4 h-4 l" />
            </span>
          </button>
        </div>
        <div>
          <NotificationAdd className="w-6 h-6 cursor-pointer " />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
