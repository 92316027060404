import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Heart, ChevronRight, Trash2 } from "lucide-react";
import { useGetFavoritesProductUserQuery } from "../../../services/services";
import { selectUserId } from "../../../services/loginReducer";

export default function ResponsiveFavoriteProductTable() {
  const userId = useSelector(selectUserId);
  const {
    data: products,
    error,
    isLoading,
  } = useGetFavoritesProductUserQuery(userId, {
    skip: !userId, // Skip the query if userId is falsy
  });

  if (isLoading) {
    return <div className="p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-center text-red-500">Error loading products</div>;
  }
  const generateCleanURL = (item) => {

    const {categoryType, categorySubType, name} =item;
    const parts = [categoryType, categorySubType, name].filter(Boolean);
    return parts.join('/').toLowerCase().replace(/\s+/g, '-');
  };


  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Favorite Products</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 overflow-y-scroll thin  ">
          {products?.map((item) => (
            <div key={item._id} className="bg-white rounded-lg shadow overflow-hidden transition-transform duration-300 hover:scale-105">
              <Link
                to={'/'+generateCleanURL(item)}
                // to={`/${item.categoryType}/${item.categorySubType}/${item.name}`}
                className="block"
              >
                <div className="p-4">
                  <div className="flex items-center justify-between mb-2">
                    <Heart fill="currentColor" className="h-5 w-5 text-red-500" />
                    <span className="text-xs font-medium text-gray-500">{item.categorySubType}</span>
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2 truncate">
                    {item.name || ""}
                  </h3>
                  <p className="text-xl font-bold text-emerald-600">
                    {item.cPrice || "Price not available"}
                  </p>
                </div>
              </Link>
              <div className="px-4 py-3 bg-gray-50 flex justify-between items-center">
                <button className="text-sm text-red-600 hover:text-red-800 flex items-center">
                  <Trash2 className="h-4 w-4 mr-1" />
                  Remove
                </button>
                <Link
                     to={'/'+generateCleanURL(item)}
                  className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
                >
                  View Details
                  <ChevronRight className="h-4 w-4 ml-1" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}