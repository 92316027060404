import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { login, selectIsSellerLoggedIn } from "../../services/loginReducer";
import { selectSellerEmail } from "../../services/loginReducer";
import { useDispatch, useSelector } from "react-redux";

export default function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailseller=useSelector(selectSellerEmail);
  const isLoggedIn = useSelector(selectIsSellerLoggedIn);
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGenerateOtp = () => {
    if (email) {
      setIsLoading(true);
      axios
        .post("/api/v1/otp/generate", { email })
        .then(() => {
          setOtpGenerated(true);
        })
        .catch((error) => {
          console.error("Error generating OTP:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && otp) {
      setIsLoading(true);
      axios
        .post("/api/v1/otp/verify", { email, otp, userType: "Seller" })
        .then((response) => {
          const token = response.data.token;
          dispatch(login({ email, token, userType: "seller" }));
          Cookies.set("sellerToken", token);
          const redirectTo = location.state?.from || "/seller/dashboard/quotation";
          navigate(redirectTo, { replace: true });
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {isLoggedIn && emailseller && (
        <Navigate
          to={location.state?.from || "/seller/dashboard/quotation"}
          replace={true}
        />
      )}
      <div className="bg-white shadow-lg border border-gray-200 rounded-lg max-w-md p-6 sm:p-8 lg:p-10 space-y-6">
        <h3 className="text-2xl font-semibold text-gray-900 text-center">
          Welcome Back! Let's Grow Your Business Together
        </h3>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="text-sm font-medium text-gray-900 block mb-2"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="name@company.com"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          {!otpGenerated ? (
            <button
              type="button"
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 transition-colors duration-300"
              onClick={handleGenerateOtp}
              disabled={isLoading}
            >
              {isLoading ? "Generating OTP..." : "Generate OTP"}
            </button>
          ) : (
            <div>
              <label
                htmlFor="otp"
                className="text-sm font-medium text-gray-900 block mb-2"
              >
                OTP
              </label>
              <input
                type="text"
                name="otp"
                id="otp"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOtpChange}
                required
              />
            </div>
          )}
          <button
            type="submit"
            className={`w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 transition-colors duration-300 ${
              !otpGenerated && "cursor-not-allowed opacity-50"
            }`}
            disabled={!otpGenerated || isLoading}
          >
            {isLoading ? "Verifying..." : "Login to your account"}
          </button>
        </form>
      </div>
    </div>
  );
}
