import React, { useState, useEffect } from "react";
import CustomerRequire from "./CustomerRequire.jsx";
import NotFound from "../utility/NotFound.jsx";
import { useGetAdminQuotationsQuery } from "../../services/services.js";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Send } from 'lucide-react';

export default function PriceOffer({ setAlert }) {
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const {
    data: sellerQuotations,
    isError: isLoadingUsersError,
    isLoading: isLoadingUsers,
    refetch,
  } = useGetAdminQuotationsQuery();

  const sendQuotation = (quotation) => {
    setSelectedQuotation(quotation);
  };

  const closeDialog = () => {
    setSelectedQuotation(null);
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedQuotations = React.useMemo(() => {
    if (!sellerQuotations) return [];
    let sortableItems = [...sellerQuotations];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sellerQuotations, sortConfig]);

  useEffect(() => {
    refetch();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedQuotations.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Quoted': return 'bg-blue-100 text-blue-800';
      case 'Accepted': return 'bg-green-100 text-green-800';
      case 'Pending': return 'bg-yellow-100 text-yellow-800';
      case 'rejected': return 'bg-red-100 text-yellow-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const getQuotationStatus = (companies) => {
    if (companies.length === 0) return 'No Companies';
    if (companies.some(company => company.status == 'Quoted')) return 'Quoted';
    if (companies.some(company => company.status == 'pending')) return 'Pending';
    return 'Unknown';
  };
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Seller Quotation Data</h2>

      {isLoadingUsers ? (
        <div className="text-center">Loading...</div>
      ) : isLoadingUsersError ? (
        <div className="text-center text-red-500">Error loading data</div>
      ) : sellerQuotations && sellerQuotations.length > 0 ? (
        <>
          <div className="overflow-x-auto bg-white shadow-md rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  {['reqId', 'PID', 'Product Name', 'Status', 'Action'].map((header) => (
                    <th
                      key={header}
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      onClick={() => sortData(header.toLowerCase())}
                    >
                      <div className="flex items-center">
                        {header}
                        {/* {sortConfig.key === header.toLowerCase() && (
                          sortConfig.direction === 'ascending' ? <ChevronUpIcon className="w-4 h-4 ml-1" /> : <ChevronDownIcon className="w-4 h-4 ml-1" />
                        )} */}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((quotation) => (
                  <tr key={quotation._id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{quotation.reqId}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{quotation.pid}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{quotation.productName}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <span className={`relative inline-block px-3 py-1 font-semibold leading-tight rounded-full ${getStatusColor(getQuotationStatus(quotation.selectedCompanies))} capitalize`}>
                        {getQuotationStatus(quotation.selectedCompanies)}
                      </span>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        disabled={!quotation.selectedCompanies.some(company => company.status == "Quoted")}
                        onClick={() => sendQuotation(quotation)}
                        className={` flex items-center text-white justify-center px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 bg-blue-600  hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2}`}
                      >
                        <Send size={16} className="mr-2" />
                        Send Quotation
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, sortedQuotations.length)} of {sortedQuotations.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
              >
                Prev
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= sortedQuotations.length}
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <NotFound />
      )
      }

      {
        selectedQuotation && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={closeDialog}>
            <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
              <CustomerRequire
                refetch={refetch}
                pid={selectedQuotation.pid}
                productName={selectedQuotation.productName}
                quotation={selectedQuotation}
                onClose={closeDialog}
                sellerQuotations={sellerQuotations.filter(q => q.pid === selectedQuotation.pid)}
              />
            </div>
          </div>
        )
      }
    </div >
  );
}