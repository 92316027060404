import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAdminEmail } from "../../services/loginReducer.js";
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NotFound from "../utility/NotFound.jsx";
import Request from "../customer/Request.jsx";
import { useAddAdminRequirementsMutation } from "../../services/services.js";
import axios from "axios";

export default function ProductRequests({ setAlert }) {
  const email = useSelector(selectAdminEmail);
  const [products, setProducts] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [data, setData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [error, setError] = useState(null);

  const [addAdminRequirements, { isLoading }] = useAddAdminRequirementsMutation();

  useEffect(() => {
    addAdminRequirements({ email, status: "Pending" })
      .unwrap()
      .then((response) => {
        if (Array.isArray(response)) {
          setProducts(response);
          setError(null);
        } else {
          console.error("Invalid response format:", response);
          setError("Invalid data received from server");
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Failed to fetch product requests");
        setProducts([]);
      });
  }, [email, showPopover, addAdminRequirements]);

  const handleSendQuotation = (data) => {
    setShowPopover(true);
    setData(data);
  };

  const handleDeleteRequest = async (productId) => {
    try {
      await axios.delete(`/api/v1/requirements/${productId}`);
      setProducts(products.filter((product) => product._id !== productId));
    } catch (error) {
      console.error("Error deleting request:", error);
      setAlert({ type: 'error', message: 'Failed to delete request. Please try again.' });
    }
  };

  const closeDialog = () => setShowPopover(false);

  const filteredProducts = useMemo(() => {
    if (!Array.isArray(products)) {
      console.error("Products is not an array:", products);
      return [];
    }

    return products.filter((product) =>
      Object.values(product).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [products, searchTerm]);

  const sortedProducts = useMemo(() => {
    let sortableItems = [...filteredProducts];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredProducts, sortConfig]);

  

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedProducts.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedProducts, currentPage, itemsPerPage]);

  const pageCount = Math.ceil(sortedProducts.length / itemsPerPage);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  if (error) {
    return <div className="text-red-500 text-center my-4">{error}</div>;
  }

  if (isLoading) {
    return <div className="text-center my-4">Loading...</div>;
  }

  return (
    <div className="container grid grid-cols-1 mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6">Enquiry Requests</h2>

      <div className="mb-6 flex justify-between items-center">
        <div className="w-1/3">
          <input
            type="text"
            placeholder="Search requests..."
            className="w-full p-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {paginatedProducts.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="product requests table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => requestSort('name')}>
                  Name
                  {sortConfig.key === 'name' && (
                    sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                  )}
                </TableCell>
                <TableCell onClick={() => requestSort('reqId')}>
                  ID
                  {sortConfig.key === 'reqId' && (
                    sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                  )}
                </TableCell>
                {/* <TableCell onClick={() => requestSort('company')}>
                  Company
                  {sortConfig.key === 'company' && (
                    sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                  )}
                </TableCell> */}
                {/* <TableCell>Quantity</TableCell> */}
                <TableCell>Specification</TableCell>
                {/* <TableCell>Email</TableCell> */}
                <TableCell onClick={() => requestSort('createdAt')}>
                  Date
                  {sortConfig.key === 'createdAt' && (
                    sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                  )}
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.reqId}</TableCell>
                  {/* <TableCell>{product.company}</TableCell> */}
                  {/* <TableCell>{product.minQty}</TableCell> */}
                  <TableCell>{product.specification}</TableCell>
                  {/* <TableCell>{product.email}</TableCell> */}
                  <TableCell>{product.createdAt ? product.createdAt.substring(0, 10) : ""}</TableCell>
                  <TableCell>
                    <span className="inline-block px-3 py-1 text-sm font-semibold text-orange-800 bg-orange-200 rounded-full">
                      {product.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <button
                      onClick={() => handleSendQuotation(product)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Request Quotation
                    </button>
                    <button
                      onClick={() => handleDeleteRequest(product._id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      <DeleteIcon />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFound />
      )}

      <div className="mt-8 flex justify-between items-center">
        <span className="text-sm text-gray-600">
          Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, sortedProducts.length)} of {sortedProducts.length} Entries
        </span>
        <div className="flex space-x-2">
          <button
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}
          >
            Next
          </button>
        </div>
      </div>

      {showPopover && (
        <Dialog open={showPopover} maxWidth="xl" onClose={closeDialog}>
          <div className="max-w-5xl min-w-2xl h-[70vh]">
            <Request
              email={email}
              addAdminRequirements={addAdminRequirements}
              setProducts={setProducts}
              setAlert={setAlert}
              product={data}
              setShowPopover={setShowPopover}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}