// src/services/loginActions.js

import axios from "axios";
import Cookies from "js-cookie";
import { login } from "./loginReducer";


export const verifyToken = (cookieName) => async (dispatch) => {
  const token = Cookies.get(cookieName);

  try {
    if (token) {
      // Verify token against the server
      const response = await axios.post("/api/v1/verifyToken", { token });

      // Assuming response.data contains userId and other user details
      const { userId, email, userType } = response.data;

      // Dispatch login action to store user information in Redux state
      dispatch(login({ userId,email, userType }));

    
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Clear the token if the response is 401 Unauthorized
      Cookies.remove(cookieName);
      console.error("Invalid token, token cleared");
    } else {
      console.error("Error verifying token:", error);
      // Handle other errors if necessary
    }
  }
};
