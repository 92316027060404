import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { 
  Dialog, 
  DialogContent, 
  DialogDescription, 
  DialogFooter, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger 
} from "../../../components/ui/dialog";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "../../../components/ui/table";
import { toast } from "../../../hooks/use-toast";
import { ScrollArea } from "../../../components/ui/scroll-area";

export default function About() {
  const [aboutData, setAboutData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    listOfImages: [{ image: { file: null, altText: '' }, title: '', description: '' }]
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get('/api/v1/aboutus');
      setAboutData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: "Error",
        description: "Failed to fetch about us data.",
        variant: "destructive",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleImageItemChange = (e, index) => {
    const { name, value, files } = e.target;
    setFormData(prev => {
      const newImages = [...prev.listOfImages];
      if (name === 'image') {
        newImages[index] = { 
          ...newImages[index], 
          image: { ...newImages[index].image, file: files[0] } 
        };
      } else if (name.startsWith('image.')) {
        const imageProp = name.split('.')[1];
        newImages[index] = { 
          ...newImages[index], 
          image: { ...newImages[index].image, [imageProp]: value } 
        };
      } else {
        newImages[index] = { ...newImages[index], [name]: value };
      }
      return { ...prev, listOfImages: newImages };
    });
    setErrors(prev => ({ ...prev, [`listOfImages.${index}.${name}`]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = 'Title is required';
    if (!formData.description.trim()) newErrors.description = 'Description is required';
    
    formData.listOfImages.forEach((item, index) => {
      if (!item.image.file && !currentItem) newErrors[`listOfImages.${index}.image.file`] = 'Image is required';
      if (!item.image.altText.trim()) newErrors[`listOfImages.${index}.image.altText`] = 'Alt text is required';
      if (!item.title.trim()) newErrors[`listOfImages.${index}.title`] = 'Title is required';
      if (!item.description.trim()) newErrors[`listOfImages.${index}.description`] = 'Description is required';
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast({
        title: "Validation Error",
        description: "Please fill out all required fields.",
        variant: "destructive",
      });
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
   
    const listOfImagesData = formData.listOfImages.map(item => ({
      image: { altText: item.image.altText },
      title: item.title,
      description: item.description
    }));
    formDataToSend.append('listOfImages', JSON.stringify(listOfImagesData));

    formData.listOfImages.forEach((item, index) => {
      if (item.image.file) {
        formDataToSend.append('images', item.image.file);
      }
    });

    try {
      if (currentItem) {
        await axios.patch(`/api/v1/aboutus/${currentItem._id}`, formDataToSend);
        toast({ title: "Success", description: "About Us item updated successfully." });
      } else {
        await axios.post('/api/v1/aboutus', formDataToSend);
        toast({ title: "Success", description: "New About Us item created successfully." });
      }
      fetchAboutData();
      setIsDialogOpen(false);
      setFormData({ 
        title: '', 
        description: '', 
        listOfImages: [{ image: { file: null, altText: '' }, title: '', description: '' }] 
      });
      setCurrentItem(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to save About Us item.",
        variant: "destructive",
      });
    }
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setFormData({
      ...item,
      listOfImages: item.listOfImages.map(img => ({
        ...img,
        image: { ...img.image, file: null }
      }))
    });
    setIsDialogOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await axios.delete(`/api/v1/aboutus/${id}`);
        toast({ title: "Success", description: "About Us item deleted successfully." });
        fetchAboutData();
      } catch (error) {
        console.error('Error deleting item:', error);
        toast({
          title: "Error",
          description: "Failed to delete About Us item.",
          variant: "destructive",
        });
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">About Us</h1>
      <Button onClick={() => setIsDialogOpen(true)}>Add New</Button>
      
      <Table>
        <TableCaption>A list of About Us items.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {aboutData.map((item) => (
            <TableRow key={item._id}>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.description.substring(0, 100)}...</TableCell>
              <TableCell>
                <Button variant="outline" className="mr-2" onClick={() => handleEdit(item)}>Edit</Button>
                <Button variant="destructive" onClick={() => handleDelete(item._id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-[90vw] w-full max-h-[90vh] h-full flex flex-col overflow-scroll">
          <DialogHeader>
            <DialogTitle>{currentItem ? 'Edit About Us Item' : 'Add New About Us Item'}</DialogTitle>
            <DialogDescription>
              Fill in the details for the About Us item.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="flex-grow flex flex-col">
            <ScrollArea className="flex-grow pr-4">
              <div className="grid gap-4 py-4">
                <Input 
                  name="title" 
                  placeholder="Title" 
                  value={formData.title} 
                  onChange={handleInputChange} 
                />
                {errors.title && <p className="text-red-500">{errors.title}</p>}
                <Textarea 
                  name="description" 
                  placeholder="Description" 
                  value={formData.description} 
                  onChange={handleInputChange} 
                />
                {errors.description && <p className="text-red-500">{errors.description}</p>}
                {formData.listOfImages.map((image, index) => (
                  <div key={index} className="border p-4 rounded">
                    <Input 
                      type="file" 
                      name="image" 
                      onChange={(e) => handleImageItemChange(e, index)} 
                    />
                    {errors[`listOfImages.${index}.image.file`] && <p className="text-red-500">{errors[`listOfImages.${index}.image.file`]}</p>}
                    <Input 
                      name="image.altText" 
                      placeholder="Alt Text" 
                      value={image.image.altText} 
                      onChange={(e) => handleImageItemChange(e, index)} 
                    />
                    {errors[`listOfImages.${index}.image.altText`] && <p className="text-red-500">{errors[`listOfImages.${index}.image.altText`]}</p>}
                    <Input 
                      name="title" 
                      placeholder="Image Title" 
                      value={image.title} 
                      onChange={(e) => handleImageItemChange(e, index)} 
                    />
                    {errors[`listOfImages.${index}.title`] && <p className="text-red-500">{errors[`listOfImages.${index}.title`]}</p>}
                    <Textarea 
                      name="description" 
                      placeholder="Image Description" 
                      value={image.description} 
                      onChange={(e) => handleImageItemChange(e, index)} 
                    />
                    {errors[`listOfImages.${index}.description`] && <p className="text-red-500">{errors[`listOfImages.${index}.description`]}</p>}
                  </div>
                ))}
                <Button type="button" onClick={() => setFormData(prev => ({ 
                  ...prev, 
                  listOfImages: [...prev.listOfImages, { image: { file: null, altText: '' }, title: '', description: '' }] 
                }))}>
                  Add Image
                </Button>
              </div>
            </ScrollArea>
            <DialogFooter className="mt-4">
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}