import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn } from "../../services/loginReducer";
import { selectSidebarTab, setSidebarTab } from "../../services/tabSlice";
import { AccountCircle, Logout, Close } from '@mui/icons-material';
import { selectAdminEmail } from "../../services/loginReducer";
const Sidebar = ({ componentArray, handleLogoutClick, isOpen, setShowSidebar }) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectSidebarTab);

  const handleClose = () => {
    setShowSidebar(false);
  };

  return (
    <>
      {/* Mobile Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden" 
          onClick={handleClose}
        ></div>
      )}

      {/* Sidebar */}
      <aside className={`
        fixed md:sticky top-0 left-0 h-full w-64 md:w-72 
        bg-gradient-to-b from-blue-600 to-blue-800 text-white
        transform transition-transform duration-300 ease-in-out z-50
        ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0
      `}>
        <div className="flex flex-col h-[90%]  p-4">
          {/* Close button for mobile */}
          <button 
            onClick={handleClose} 
            className="md:hidden self-end text-white hover:text-gray-300 transition-colors duration-200"
          >
            <Close fontSize="large" />
          </button>

          {/* Profile Section */}
          <div className="flex items-center space-x-4 mb-8 mt-4">
            <AccountCircle fontSize="large" />
            <div>
              <h2 className="font-bold">Sabecho</h2>
              <p className="text-sm text-blue-200">Admin</p>
            </div>
          </div>

          {/* Navigation */}
          <nav className="flex-grow">
            <ul className="space-y-2 capitalize">
              {componentArray.map((component, index) => (
                <li key={index}>
                  <Link
                    to={component.path}
                    className={`
                      flex items-center p-3 rounded-lg transition-all duration-200
                      ${selectedTab === component.path
                        ? 'bg-white text-blue-600 shadow-md'
                        : 'text-white hover:bg-blue-700'
                      }
                    `}
                    onClick={() => {
                      dispatch(setSidebarTab(component.path));
                      // handleClose();
                    }}
                  >
                    {React.cloneElement(component.icon, { className: "mr-3" })}
                    <span>{component.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Logout Button */}
          <button
            onClick={handleLogoutClick}
            className="mt-auto flex items-center p-3 text-white hover:bg-red-500 rounded-lg transition-all duration-200"
          >
            <Logout className="mr-3" />
            <span>Logout</span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;