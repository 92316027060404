import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Logo.png";
export default function Footer() {
  const navData = [
    {
      title: "Company",
      links: [
        { label: "About Us", href: "/about" },
        { label: "Contact", href: "/contact" },
  
      ],
    },
    {
      title: "Products",
      links: [
        { label: "Raw Materials", href: "/polymers-and-packaging/premium-boxes" },
        { label: "Packaging Solutions", href: "/polymers-and-packaging/tapes" },
      ],
    },
    {
      title: "Resources",
      links: [
        // { label: "Material Data Sheets", href: "/data-sheets" },
        // { label: "Industry Insights", href: "/blog" },
        { label: "FAQ", href: "/contact" },
      ],
    },
  ];

  return (
    <footer className="w-full text-white mt-5 bg-gradient-to-l from-blue-800 to-gray-900 body-font">
      <div className="container flex flex-col flex-wrap px-5 py-24 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap">
        <div className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left">
          <Link
            to="/"
            className="flex items-center justify-center font-medium title-font md:justify-start"
            aria-label="Home"
          >
            <img
              src={logo}
              title="sabecho logo"
              loading="lazy"
              width="160"
              height="40"
              className="shadow-2xl"
              alt="Logo"
            />
            {/* <span className="ml-3 text-xl">Sabecho</span> */}
          </Link>
          <p className="mt-2 text-sm">Empowering Industries with Premium Raw Materials</p>
        </div>
        <div className="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
          {navData.map((section, index) => (
            <div key={index} className="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 className="mb-3 text-sm font-medium tracking-widest uppercase title-font">
                {section.title}
              </h2>
              <ul className="mb-10 list-none">
                {section.links.map((link, idx) => (
                  <li key={idx} className="mt-3">
                    <Link
                      to={link.href}
                      className="cursor-pointer hover:underline"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="w-full px-4 lg:w-1/4 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest uppercase title-font">
              Contact Us
            </h2>
            <ul className="mb-10 list-none">
              <li className="mt-3">
                <Link
                  to="/contact"
                  className="cursor-pointer hover:underline"
                  aria-label="Contact Sales"
                >
                  Contact Sales
                </Link>
              </li>
              {/* <li className="mt-3">
                <Link
                  to="/request-quote"
                  className="cursor-pointer hover:underline"
                  aria-label="Request a Quote"
                >
                  Request a Quote
                </Link>
              </li> */}
              <li className="mt-3">
                <a
                  href="tel:+18001234567"
                  className="cursor-pointer hover:underline"
                  aria-label="Phone number +1 (800) 123-4567"
                >
                  +91 8000324964
                </a>
              </li>
              <li className="mt-3">
                <a
                  href="mailto:sales@rawmatsolutions.com"
                  className="cursor-pointer hover:underline"
                  aria-label="Email sales@rawmatsolutions.com"
                >
                  info@sabecho.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="container px-5 py-4 mx-auto">
          <p className="text-sm text-center text-gray-300">
            © 2024 Sabecho. All rights reserved. |
            <Link to="/terms" className="ml-1 hover:underline">Terms of Service</Link> |
            <Link to="/privacy" className="ml-1 hover:underline">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </footer>
  );
}