import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  selectIsSellerLoggedIn,
  selectSellerEmail,
  selectUserEmail,
} from "../../services/loginReducer";
// import { useDispatch } from "react-redux";

export default function UpdateProfile({ userType, closeModal, refetch }) {
  const sellEmail = useSelector(selectSellerEmail);
  const userEmail = useSelector(selectUserEmail);
  const login = useSelector(selectIsSellerLoggedIn);

  const email = userType === "seller" ? sellEmail : userEmail;

  const [userData, setUserData] = useState({
    email: email,
    name: "",
    companyName: "",
    mobileNo: "",
    gstNo: "",
    pincode: "",
  });

  useEffect(() => {
    // Fetch user data when component mounts
    fetchUserData();
  }, [email]); // Re-fetch user data when email changes

  const fetchUserData = async () => {
    if (email != null && email !== undefined) {
      try {
        const response = await axios.get("/api/v1/profile", {
          params: { email: email },
        });
        const userDataFromApi = response.data; // Assuming the response contains user data
        setUserData(userDataFromApi);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put("/api/v1/update-users", userData).then((response) => {
        refetch();
        closeModal();
      });
      // Reset user data after successful submission if needed
      closeModal();
    } catch (error) {
      console.error("Error submitting user data:", error);
    }
  };

  return (
    <div className="md:my-4 mx-4 m-1">
      <div className="flex justify-center items-center my-6 uppercase font-semibold">
        <h2 className="text-lg">Update Details</h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-wrap justify-center items-center gap-6 w-full"
      >
        {/* Input fields */}
        <div className="relative h-10 w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="Email"
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="Name"
            type="text"
            name="name"
            value={userData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="Company Name"
            type="text"
            name="companyName"
            value={userData.companyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="Mobile Number"
            type="tel"
            name="mobileNo"
            value={userData.mobileNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="GST Number"
            type="text"
            name="gstNo"
            value={userData.gstNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-72">
          <input
            className="peer h-full w-full rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-500 focus:border-2 focus:border-gray-500 disabled:border-0 disabled:bg-gray-50"
            placeholder="Pincode"
            type="number"
            name="pincode"
            value={userData.pincode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="relative h-10 min-w-[200px] w-full flex justify-center">
          <button
            className="bg-blue-700 text-white rounded-lg px-5 py-2.5 font-medium text-sm"
            type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
