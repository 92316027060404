import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { selectUserEmail } from "../../../services/loginReducer";
import { selectMainTab, setMainTab } from "../../../services/tabSlice";
import { Clock, CheckCircle, Bookmark, Activity } from "lucide-react";
import CustomerQuoted from "./CustomerQuoted";
import Pending from "./Pending";
import Advertisement from "../../utility/Advertisement";
import CompletedOrders from "./CompletedOrders.jsx";
import ActiveOrders from "./ActiveOrders.jsx";

const Tracking = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const email = useSelector(selectUserEmail);
  const selectedTab = useSelector(selectMainTab);

  const tabs = [
    { name: "Pending", status: "Pending", icon: Clock },
    { name: "Quoted", status: "Quoted", icon: Bookmark },
    { name: "Completed", status: "Completed", icon: CheckCircle },
    { name: "Active", status: "Active", icon: Activity },
  ];

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.post("/api/v1/requirementsByEmail", {
          email,
          status: selectedTab,
        });
        setDetails(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };

    if (email) {
      fetchDetails();
    }
  }, [selectedTab, email]);

  const handleTabChange = (newValue) => {
    dispatch(setMainTab(newValue));
    setError(null);
    setDetails([]);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "bg-yellow-400 text-black";
      case "quoted":
        return "bg-green-500 text-white";
      case "active":
        return "bg-blue-500 text-white";
      case "completed":
        return "bg-purple-500 text-white";
      default:
        return "bg-gray-200 text-black";
    }
  };

  const renderDetails = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      );
    } else if (error) {
      return <div className="text-red-500 p-4">{error}</div>;
    } else if (details.length === 0) {
      return (
        <div className="text-center p-4">
          No {selectedTab.toLowerCase()} requirements found
        </div>
      );
    } else {
      switch (selectedTab.toLowerCase()) {
        case "pending":
          return details.map((detail) => (
            <Pending
              key={detail._id}
              detail={detail}
              selectedTab={selectedTab}
              getStatusClass={getStatusClass}
            />
          ));
        case "quoted":
          return <CustomerQuoted getStatusClass={getStatusClass} />;
        case "active":
          return (
            <ActiveOrders details={details} getStatusClass={getStatusClass} />
          );
        case "completed":
          return (
            <CompletedOrders
              details={details}
              getStatusClass={getStatusClass}
            />
          );
        default:
          return null;
      }
    }
  };

  return (
    <div className="p-4 h-full md:h-[calc(100vh-64px)] bg-gray-100">
      <div className="max-w-7xl mx-auto overflow-y-auto thin  ">
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Order Tracking</h1>
          <p className="text-sm text-gray-600">Monitor your order status</p>
        </div>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="flex flex-col sm:flex-row">
            {tabs.map((tab) => {
              const IconComponent = tab.icon;
              return (
                <button
                  key={tab.status}
                  className={`flex items-center justify-center px-4 py-3 text-sm font-medium transition-colors duration-200 ${
                    selectedTab === tab.status
                      ? getStatusClass(tab.status)
                      : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                  } ${
                    selectedTab === tab.status ? "border-b-2 border-current" : ""
                  }`}
                  onClick={() => handleTabChange(tab.status)}
                >
                  <IconComponent className="w-5 h-5 mr-2" />
                  <span className="hidden sm:inline">{tab.name}</span>
                </button>
              );
            })}
          </div>
          <div className="p-4">
            <div className="overflow-y-auto thin  md:h-[50vh]">
              {renderDetails()}
            </div>
          </div>
        </div>
        {selectedTab === "Pending" && (
          <div className="mt-6">
            <Advertisement />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tracking;