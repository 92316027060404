import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Button, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCategory from "../product/AddCategory";
import {
  selectSellerEmail,
  selectAdminEmail,
} from "../../services/loginReducer";

const ProductLists = ({ admin }) => {
  const email = useSelector(selectSellerEmail);
  const adminEmail = useSelector(selectAdminEmail);

  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const selectedEmail = admin === "admin" ? adminEmail : email;

    axios
      .post("/api/v1/products/match", {
        email: selectedEmail,
        type: admin,
      })
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [email, adminEmail, showForm, admin]);

  const handleCreateProduct = async ({ values, table }) => {
    if (admin !== "admin") return; // Only admin can create
    try {
      await axios.post("/api/v1/products", values);
      table.setCreatingRow(null);
      setShowForm(false);
      refreshProducts();
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const handleSaveProduct = async ({ values, table }) => {
    if (admin !== "admin") return; // Only admin can update
    try {
      await axios.put(`/api/v1/products/${values._id}`, values);
      table.setEditingRow(null);
      refreshProducts();
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  const handleDelete = async (id) => {
    if (admin !== "admin") return; // Only admin can delete
    try {
      await axios.delete(`/api/v1/products/${id}`);
      refreshProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const refreshProducts = async () => {
    const selectedEmail = admin === "admin" ? adminEmail : email;
    try {
      const response = await axios.post("/api/v1/products/match", {
        email: selectedEmail,
        type: admin,
      });
      setProducts(response.data);
    } catch (error) {
      console.error("Error refreshing products:", error);
    }
  };



  const data = [
    {
      pid: 1717222564205,
      name: "Mouse",
      cPrice: 900,
      brand: "hp",
      location: "daman",
      description: "Hello World",
      measurements: ["units"],
      state: "",
      region: "",
      pincode: "396210",

      categoryType: "Non Ferrous",
      categorySubType: "New Subcategory",
      img: "https://example.com/aluminum-sheet.jpg",
      currency: "Rupees",
      grades: ["Grade A"],
      // _id: "665abca45c14142e20c8e6ce",
      minQty: 50,
    },
  ];

  const columns = useMemo(() => {
    if (!products.length) return []; // Handle empty data array
    return Object.keys(data[0]).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
      size: 50, // Set the default size
    }));
  }, [products]);

  const columnVirtualizerInstanceRef = useRef(null);

  const table = useMaterialReactTable({
    columns,
    data: products,
    enableRowNumbers: true,
    initialState: {
      density: "compact",
      columnVisibility: {
        // ... (same as before)
      },
      pagination: { pageSize: 7, pageIndex: 0 },
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [7, 10, 15],
      variant: "outlined",
    },
    paginationDisplayMode: "pages",
    enableEditing: admin === "admin", // Only allow editing for admin
    getRowId: (row) => row._id,
    onCreatingRowSave: handleCreateProduct,
    onEditingRowSave: handleSaveProduct,

    renderRowActions: ({ row, table }) => (
      admin === "admin" && (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      admin === "admin" && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowForm(true)}
        >
          Add New Product
        </Button>
      )
    )
  });

  return (
    <>
      <div>
        <div>
          <span className="text-2xl font-semibold">
            {admin === "admin" ? "Admin" : "Seller"} Product List
          </span>
        </div>
        <div>
          <p className="text-sm py-3 text-gray-500">
            Below is a list of products along with their details:
          </p>
        </div>
        <div className="overflow-x-auto thin ">
          <MaterialReactTable table={table} />
        </div>
      </div>
      {showForm && admin === "admin" && <AddCategory setShowForm={setShowForm} />}
    </>
  );
};

export default ProductLists;