import React, { useState } from "react";
import { Navigate, Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn } from "../../services/loginReducer";
import { verifyToken } from "../../services/loginActions.js";
import { useEffectOnce } from "../customeHook/hook.js";
import { selectSidebarTab, setSidebarTab } from "../../services/tabSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListIcon from "@mui/icons-material/List";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from './Components/Sidebar.jsx';
import {Logout, Close } from '@mui/icons-material';
export default function Dashboard() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const selectedTab = useSelector(selectSidebarTab);

  useEffectOnce(() => {
    dispatch(verifyToken("token"));
  });

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  const componentArray = [
    { name: "Profile", path: "profile", icon: <AccountCircleIcon /> },
    { name: "Tracking", path: "Tracking-list", icon: <ListIcon /> },
    { name: "My Favorite", path: "my-favorite", icon: <FavoriteIcon /> },
  ];

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  if (!isLoggedIn) return <Navigate to="/" replace={true} />;

  return (
    <div className="flex flex-col h-[80vh] bg-gray-100 sm:overflow-hidden ">
      {/* Main content area */}
      <div className="flex-1 flex flex-col md:flex-row">
        {/* Sidebar for desktop */}
        <div className="hidden md:block m-4  ">
          <Sidebar
            componentArray={componentArray}
            handleLogoutClick={handleLogoutClick}
            isOpen={true}
            onClose={handleCloseSidebar}
          />
        </div>

        {/* Mobile sidebar */}
        <div className="md:hidden">
          <Sidebar
            componentArray={componentArray}
            handleLogoutClick={handleLogoutClick}
            isOpen={sidebarOpen}
            onClose={handleCloseSidebar}
          />
        </div>

        {/* Content area */}
        <div className="flex-1 scrollnone flex flex-col overflow-y-scroll thin  sm:overflow-hidden">
          {/* Header */}
          {/* <header className="bg-white shadow-sm z-10  sm:hidden">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <button
                className="md:hidden text-gray-500 hover:text-gray-700"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <MenuIcon />
              </button>
            </div>
          </header> */}

          {/* Mobile navigation */}
          <nav className="bg-white shadow-sm md:hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex gap-5 justify-between items-center  overflow-x-auto thin ">
                {componentArray.map((component, index) => (
                  <Link
                    key={index}
                    to={`/dashboard/${component.path}`}
                    className={`flex flex-col items-center py-3 px-1 border-b-2 text-sm font-medium ${
                      selectedTab === component.path
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                    onClick={() => dispatch(setSidebarTab(component.path))}
                  >
                    {React.cloneElement(component.icon, { className: "mb-1" })}
                    <span>{component.name}</span>
                  </Link>
                ))}
                <div className="flex flex-col items-center justify-center">
                <button
                  onClick={handleLogoutClick}
                  className={`  px-1  text-sm font-medium`}
                >
                  <Logout color="red" className=" text-red-500" />
                
                </button>
              </div>
              </div>
            </div>
          </nav>

          {/* Page content */}
          <main className="flex-1  sm:overflow-hidden sm:h-[60vh] overflow-y-auto thin  bg-gray-100">
            <div className="max-w-7xl mx-4 scrollnone   overflow-y-scroll thin   lg:px-3 mt-4">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}