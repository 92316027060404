import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: 60,
    height: 60,
  },
  headerRight: {
    textAlign: "right",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  invoiceInfo: {
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
  },
  tableCell: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
  summaryTable: {
    width: "50%",
    alignSelf: "flex-end",
  },
  footer: {
    marginTop: 30,
    borderTopWidth: 1,
    borderTopColor: "#bfbfbf",
    paddingTop: 10,
    fontSize: 8,
    color: "gray",
  },
});

// Create Document Component
const PaymentInvoice = ({ detail,newAmount }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Image
          style={styles.logo}
          src="/path/to/your/logo.png" // Replace with your logo path
        />
        <View style={styles.headerRight}>
          <Text style={styles.title}>INVOICE</Text>
          <Text>Sabecho Inc.</Text>
          <Text>45 V2 Sighnature, Chala, Vapi</Text>
          <Text>Gujarat, India, 396210</Text>
          <Text>Email: sabecho@info.com</Text>
          <Text>Phone: +91 8347232980</Text>
        </View>
      </View>

      {/* Invoice Info */}
      <View style={styles.invoiceInfo}>
        <Text>Invoice Number: INV-{detail.paymentId}</Text>
        <Text>Date: {new Date(detail.createdAt).toLocaleDateString()}</Text>
        <Text>Payment Status: {detail.status}</Text>
      </View>

      {/* Customer Details */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Bill To:</Text>
        <Text>{detail.paymentDetails.email}</Text>
        <Text>Contact: {detail.paymentDetails.contact}</Text>
        <Text>{detail.paymentDetails.notes.address}</Text>
      </View>

      {/* Order Details Table */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Order Details</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "30%" }]}>Product</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "15%" }]}>Qty</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "15%" }]}>Measurement</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "20%" }]}>Rate</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "20%" }]}>Amount</Text>
          </View>
          {detail.orderDetails.map((order, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, { width: "30%" }]}>{order.productName}</Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>{order.minQty}</Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>{order.measurement}</Text>
              <Text style={[styles.tableCell, { width: "20%" }]}>{(newAmount ||order.amount / order.minQty).toFixed(2)}</Text>
              <Text style={[styles.tableCell, { width: "20%" }]}>{newAmount ||order.amount}</Text>
            </View>
          ))}
        </View>
      </View>

      {/* Payment Summary */}
      <View style={[styles.section, styles.summaryTable]}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: "50%" }]}>Subtotal:</Text>
            <Text style={[styles.tableCell, { width: "50%" }]}>{newAmount || detail.amount}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: "50%" }]}>GST ({detail.orderDetails[0].gstPercentage}%):</Text>
            <Text style={[styles.tableCell, { width: "50%" }]}>{(newAmount || detail.amount * detail.orderDetails[0].gstPercentage / 100).toFixed(2)}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "50%" }]}>Total Amount:</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader, { width: "50%" }]}>{detail.amount}</Text>
          </View>
        </View>
      </View>

      {/* Additional Information */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Payment Information</Text>
        <Text>Payment ID: {detail.paymentId}</Text>
        <Text>Payment Method: {detail.paymentDetails.method}</Text>
        <Text>Transaction ID: {detail.paymentDetails.acquirer_data.upi_transaction_id}</Text>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Thank you for your business!</Text>
        <Text>
          For any questions concerning this invoice, please contact us at sabecho@info.com or +91 8347232980.
        </Text>
        <Text>
          Terms & Conditions: [Your terms and conditions here]
        </Text>
      </View>
    </Page>
  </Document>
);

export default PaymentInvoice;