import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { selectUserEmail, selectUserId } from "../../../services/loginReducer";
import { selectMainTab } from "../../../services/tabSlice";
import MyDocument from "./MyDocument";

const CustomerQuoted = ({ getStatusClass }) => {
  const id = useSelector(selectUserId);
  const email = useSelector(selectUserEmail);
  const selectedTab = useSelector(selectMainTab);
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pidList, setPidList] = useState([]);
  const [negotiationCount, setNegotiationCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNegotiation, setCurrentNegotiation] = useState(null);

  useEffect(() => {
    axios
      .post("/api/v1/quotaRequirement/get", {
        email: email,
        status: selectedTab,
      })
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [email, selectedTab]);


  const handleCheckout = () => {
    const selectedProducts = products.filter(product => selectedItems.includes(product.reqId));
    const orderDetails = selectedProducts.map(product => ({
      reqId: product.reqId,
      pid: product.pid,
      amount: getNegotiationPrice(product)
    }));

    navigate(`/checkout/${id}`, {
      state: {
        Orders: orderDetails,
        products: pidList,
      },
    });
  };

  const handleNegotiations = () => {
    const selectedProduct = products.find(product => product.reqId === negotiationCount);
    if (selectedProduct) {
      navigate(`/negotiation/${id}`, {
        state: {
          order: negotiationCount,
          productId: selectedProduct.pid,
          amount: getNegotiationPrice(selectedProduct)
        },
      });
    }
  };

  const toggleItemSelection = (product) => {
    setSelectedItems((prev) =>
      prev.includes(product.reqId)
        ? prev.filter((id) => id !== product.reqId)
        : [...prev, product.reqId]
    );
    setPidList((prev) =>
      prev.includes(product.pid)
        ? prev.filter((pid) => pid !== product.pid)
        : [...prev, product.pid]
    );
    if (product.negotiation) {
      setNegotiationCount((prev) => (prev === product.reqId ? 0 : product.reqId));
    }
  };

  const handleCheckUpdates = (product) => {
    setCurrentNegotiation(product.negotiationDetails);
    setIsModalOpen(true);
  };

  const getNegotiationPrice = (product) => {
    if (product.negotiationDetails && product.negotiationDetails.newAmount) {
      return Math.round(product.negotiationDetails.newAmount);
    }
    return Math.round(product.amount);
  };

  const getNegotiationQuantity = (product) => {
    if (product.negotiationDetails && product.negotiationDetails.negotiationQuantity) {
      return product.negotiationDetails.negotiationQuantity;
    }
    return product.minQty || 0;
  };

  return (
    <div className="p-4">
      <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-2 mb-4">
        <button
          onClick={handleNegotiations}
          disabled={!negotiationCount}
          className="py-2 px-3 text-sm font-semibold rounded-lg bg-blue-600 text-white disabled:opacity-50 disabled:bg-blue-300"
        >
          Request Negotiation ({negotiationCount ? 1 : 0})
        </button>
        <button
          onClick={handleCheckout}
          disabled={!selectedItems.length}
          className="py-2 px-3 text-sm font-semibold rounded-lg bg-green-500 text-white disabled:opacity-50 disabled:bg-green-300"
        >
          Place Order ({selectedItems.length})
        </button>
      </div>

      {products.length === 0 ? (
        <div className="text-gray-600 text-center py-4">No data available</div>
      ) : (
        products.map((product) => (
          <div key={product._id} className="mb-4 bg-white rounded-lg shadow-md ">
            <div className="p-4 border-b">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="w-4 h-4 rounded text-blue-600"
                    checked={selectedItems.includes(product.reqId)}
                    onChange={() => toggleItemSelection(product)}
                  />
                  <span className="text-sm font-semibold">ID: {product.reqId}</span>
                </div>
                <div>
                  <span className={`text-xs font-bold uppercase px-2 py-1 rounded ${getStatusClass(product.status)}`}>
                    {product.status}
                  </span>
                  {product.negotiationDetails && (
                    <button
                      className="text-xs ml-5 font-bold uppercase bg-blue-500 text-white px-2 py-1 rounded inline-block mt-1"
                      onClick={() => handleCheckUpdates(product)}
                    >
                      Check Updates
                    </button>
                  )}
                </div>
              </div>
              {!product.negotiation && (
                <div className="text-xs font-bold uppercase bg-yellow-500 text-white px-2 py-1 rounded inline-block mt-1">
                  Not Negotiable
                </div>
              )}
            </div>
            <div className="p-4 sm:grid grid-cols-4">
              <div className="mb-2">
                <span className="text-xs text-gray-500 uppercase">Item: </span>
                <span className="text-sm font-medium">{product.productName}</span>
              </div>
              <div className="mb-2">
                <span className="text-xs text-gray-500 uppercase">Description: </span>
                <span className="text-sm">{product.description}</span>
              </div>
              <div className="mb-2">
                <span className="text-xs text-gray-500 uppercase">HSN Code: </span>
                <span className="text-sm">{product.hsnCode}</span>
              </div>
              <div className="mb-2">
                <span className="text-xs text-gray-500 uppercase">GST: </span>
                <span className="text-sm">{product.gstPercentage}%</span>
              </div>
              <div className="text-xs text-blue-700 mt-2 col-span-4">
                All transporting and GST charges are additional to the basic amount.
              </div>
            </div>
            <div className="p-4 bg-gray-50 flex justify-between items-center">
              <PDFDownloadLink
                document={<MyDocument detail={product} price={getNegotiationPrice(product)} />}
                fileName="quotation.pdf"
              >
                <button className="text-xs bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-3 rounded inline-flex items-center">
                  <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>Quotation PDF</span>
                </button>
              </PDFDownloadLink>
              <div className="text-right">
                <div className="text-lg font-bold">₹{getNegotiationPrice(product)}</div>
                <div className="text-sm text-gray-600">
                  ({getNegotiationQuantity(product)} {product.measurement})
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Latest Negotiation Details</h2>
            {currentNegotiation && (
              <div>
                <p><strong>New Amount:</strong> ₹{currentNegotiation.newAmount}</p>
                <p><strong>Negotiation Amount:</strong> ₹{currentNegotiation.negotiationAmount}</p>
                <p><strong>Negotiation Quantity:</strong> {currentNegotiation.negotiationQuantity}</p>
                <p><strong>Preview Amount:</strong> ₹{currentNegotiation.previewAmount}</p>
                <p><strong>Preview Quantity:</strong> {currentNegotiation.previewQuantity}</p>
                <p><strong>Comment:</strong> {currentNegotiation.comment}</p>
              </div>
            )}
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerQuoted;