import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

// Function to get token from cookies
const getToken = () => Cookies.get("token");
const getAdminToken = () => Cookies.get("adminToken");
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/",
  }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => "categories",
    }),

    getCategoryNames: builder.query({
      query: () => "categories/names",
    }),
    getAdminQuotations: builder.query({
      query: () => "adminQuotations/pending",
    }),
    getAdminCustomerNegotations: builder.query({
      query: () => "/negotiationAll",
    }),
    // /api/v1/categories/all
    getCategoriesAll: builder.query({
      query: () => "categories/all",
    }),
    getProductList: builder.query({
      query: () => "products/list",
    }),
    getNavbarData: builder.query({
      query: () => "category/navbardata",
    }),
    getMeasurementData: builder.query({
      query: () => "measurement/list",
    }),
    getSubcategories: builder.query({
      query: (categoryId) => `categories/${categoryId}/subcategories`,
    }),
    getProducts: builder.query({
      query: (category_name) => `products/${category_name}`,
    }),
    getProductsDetails: builder.query({
      query: (pid) => `/products/code/${pid}`,
    }),

    // getUser: builder.query({
    //   query: (email) => `profile?email=${email}`,
    // }),

    getUser: builder.query({
      query: (email) => ({
        url: `profile?email=${email}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
      }),
    }),

    getFavoritesProduct: builder.query({
      query: (userId) => ({
        url: `/favorite/${userId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
      }),
    }),
    getOrderDetails: builder.query({
      query: (id) => ({
        url: `/quotaRequirement/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
      }),
    }),

    getFavoritesProductUser: builder.query({
      query: (userId) => ({
        url: `/favorites/matched/${userId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
      }),
    }),

    addCategory: builder.mutation({
      query: (categoryData) => ({
        url: "categories",
        method: "POST",
        body: categoryData,
      }),
    }),
    addSubCategory: builder.mutation({
      query: ({ category, subCategory }) => ({
        url: "categories",
        method: "POST",
        body: { category, subCategory },
      }),
    }),
    quotationMatch: builder.mutation({
      query: ({ email }) => ({
        url: "quotation/match/pending",
        method: "POST",
        body: { email },
      }),
    }),
    addProduct: builder.mutation({
      query: ({ productData }) => ({
        url: "products", // Assuming 'products' is the endpoint for adding a product
        method: "POST",
        body: productData,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ productData }) => ({
        url: "products", // Assuming 'products' is the endpoint for adding a product
        method: "put",
        body: productData,
      }),
    }),
    deleteCategory: builder.mutation({
      query: ({ productData }) => ({
        url: "products", // Assuming 'products' is the endpoint for adding a product
        method: "delete",
        body: productData,
      }),
    }),

    addAdminRequirements: builder.mutation({
      query: ({ email, status }) => ({
        url: "admin/requirements",
        method: "POST",
        body: { email, status },
      }),
    }),
    saveFavorite: builder.mutation({
      query: (email, productName) => ({
        url: "favorite/save",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
        body: { email, productName },
      }),
    }),
    // Negotiation endpoints
    createNegotiation: builder.mutation({
      query: (negotiationData) => ({
        url: "negotiation",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
        method: "POST",
        body: negotiationData,
      }),
    }),

    getNegotiations: builder.query({
      query: ({ status, email }) => ({
        url: `negotiations/${status}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Include the token in the Authorization header
        },
        params: { email },
      }),
    }),

    updateNegotiation: builder.mutation({
      query: ({ negotiationId, comment,newAmount }) => ({
        url: `negotiations/${negotiationId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAdminToken()}`, // Include the token in the Authorization header
        },
        method: "PUT",
        body: {comment,newAmount},
      }),
    }),

    updateNegotiationStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `negotiations/${id}/status`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAdminToken()}`, // Include the token in the Authorization header
        },
        body: { status },
      }),
    }),

    deleteNegotiation: builder.mutation({
      query: (id) => ({
        url: `negotiations/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAdminToken()}`, // Include the token in the Authorization header
        },
        method: "DELETE",
      }),
    }),

    getNegotiationForEdit: builder.query({
      query: (id) => `negotiations/${id}/edit`,
    }),
    getAllNegotiation: builder.query({
      query:(status)=>`negotiations/${status}`,
    }),
    gettoSeller: builder.query({
      query:()=>"toseller",
    }),
    gettoCustomer: builder.query({
      query:()=>"tocustomer",
    })
  }),
});

export const {
  useGettoCustomerQuery,
  useGettoSellerQuery,
  useGetFavoritesProductQuery,
  useGetFavoritesProductUserQuery,
  useGetAdminQuotationsQuery,

  useGetAdminCustomerNegotationsQuery,
  useGetCategoriesAllQuery,
  useGetMeasurementDataQuery,
  useGetProductListQuery,
  useGetProductsQuery,
  useGetNavbarDataQuery,
  useGetCategoriesQuery,
  useGetSubcategoriesQuery,
  useGetUserQuery,
  useGetCategoryNamesQuery,
  useGetOrderDetailsQuery,
  useGetProductsDetailsQuery,

  useAddCategoryMutation,
  useAddSubCategoryMutation,
  useAddProductMutation,
  useAddAdminRequirementsMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useQuotationMatchMutation,
  useSaveFavoriteMutation,
  useCreateNegotiationMutation,
  useGetNegotiationsQuery,
  useUpdateNegotiationMutation,
  useUpdateNegotiationStatusMutation,
  useDeleteNegotiationMutation,
  useGetNegotiationForEditQuery,
  
  useGetAllNegotiationQuery
} = api;
