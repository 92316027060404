import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { useGetAdminQuotationsQuery } from "../../services/services";

export default function CustomerRequire({
  productName,
  onClose,
  quotation,
  pid,
  sellerQuotations,
}) {
  const [productCompanies, setProductCompanies] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [total, setTotal] = useState(0);
  const [percentage, setPercentage] = useState(15);
  const [buyerInfo, setBuyerInfo] = useState(null);
  const [sellerInfo, setSellerInfo] = useState(null);

  const { refetch } = useGetAdminQuotationsQuery();

  useEffect(() => {
    axios
      .post("/api/v1/requirements/offer", {
        name: productName,
        status: "Accepted",
        pid: quotation.pid,
        specification: quotation.specification,
      })
      .then((response) => {
        setProductCompanies(response.data);
        // Fetch buyer information
        if (response.data[0]?.email) {
          fetchUserInfo(response.data[0].email, setBuyerInfo);
        }
      })
      .catch((error) => {
        console.error("Error fetching product companies:", error);
      });
  }, [productName, quotation.pid, quotation.specification]);

  useEffect(() => {
    if (selectedSeller) {
      const initialTotal = selectedSeller.amount + selectedSeller.amount * (percentage / 100);
      setTotal(initialTotal.toFixed(2));
      // Fetch seller information
      fetchUserInfo(selectedSeller.email, setSellerInfo);
    }
  }, [selectedSeller, percentage]);

  const fetchUserInfo = async (email, setInfo) => {
    try {
      const response = await axios.post('/api/v1/profile', { email });
      setInfo(response.data);
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };


  // 1.call one pass user 
  const handleSubmit = async () => {
    if (!selectedSeller) {
      alert("Please select a seller first.");
      return;
    }

    try {
      await axios.put(`/api/v1/update-quotations/${quotation._id}`, {
        status: "Quoted",
      });

      await axios.put(
        `/api/v1/update-requirement/${productCompanies[0]._id}`,
        { status: "Quoted" }
      );

      const negotiation = document.getElementById("negotiation").checked;

      const newCombinedItem = {
        status: "Quoted",
        productName: quotation.productName,
        minQty: quotation.averageQty,
        commission: percentage,
        seller_email: selectedSeller?.email,
        seller_amount: selectedSeller.amount,
        amount: total,
        reqId: quotation?.reqId,
        measurement: quotation.measurement,
        description: selectedSeller.description,
        company: productCompanies[0].company,
        pincode: productCompanies[0].pincode,
        buyer_email: productCompanies[0]?.email,
        mobile: productCompanies[0].mobile,
        hsnCode: productCompanies[0].hsnCode[0],
        gstPercentage: productCompanies[0].gstPercentage[0],
        pid: quotation?.pid,
        specification: quotation?.specification,
        negotiation: negotiation,
      };

      await axios.post("/api/v1/quotaRequirement", newCombinedItem);
      refetch();
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  function calculateTotal(newPercentage) {
    if (selectedSeller) {
      const totalAmount = selectedSeller.amount + selectedSeller.amount * (newPercentage / 100);
      setPercentage(newPercentage);
      setTotal(totalAmount.toFixed(2));
    }

  }
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <div className="bg-gray-100 p-4">
        <div className="mx-auto max-w-5xl space-y-2">
          <div className="rounded-lg bg-white p-4 shadow-lg">
            <h2 className="mb-6 text-xl font-bold text-gray-800">
              Select Seller
            </h2>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Seller
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sellerQuotations && sellerQuotations[0].selectedCompanies.map((seller) => (
                  <tr key={seller._id}>
                    {console.log(seller)}
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {seller?.email}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {seller?.amount}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {seller?.description}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => setSelectedSeller(seller)}
                        className={`px-4 py-2 rounded ${selectedSeller === seller
                          ? "bg-blue-600 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                          }`}
                      >
                        {selectedSeller === seller ? "Selected" : "Select"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="grid grid-cols-2 gap-2 md:grid-cols-2">
            <div className="rounded-lg bg-white p-4 shadow-lg">
              <h2 className="mb-6 text-xl font-bold text-gray-800">
                Buyer Information
              </h2>
              <div className="space-y-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {buyerInfo && (
                      <>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Name</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.name}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Company Name</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.companyName}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Email</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.email}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Mobile No</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.mobileNo}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">GST No</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.gstNo}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">User Type</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.userType}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Pincode</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.pincode}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">User ID</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{buyerInfo.userId}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="rounded-lg bg-white p-4 shadow-lg">
              <h2 className="mb-6 text-xl font-bold text-gray-800">
                Seller Information
              </h2>
              <div className="space-y-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sellerInfo && (
                      <>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Name</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.name}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Company Name</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.companyName}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Email</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.email}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Mobile No</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.mobileNo}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">GST No</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.gstNo}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">User Type</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.userType}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">Pincode</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.pincode}</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-800">User ID</td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">{sellerInfo.userId}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white p-4 shadow-lg">
            <h2 className="mb-6 text-xl font-bold text-gray-800">
              Product Details
            </h2>
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto thin ">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="border rounded-lg shadow overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-800">
                            {quotation?.pid}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-800">
                            {quotation?.productName}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-800">
                            {quotation?.averageQty} {quotation?.measurement}
                          </td>
                          <td className="px-4 font-bold py-3 whitespace-nowrap text-sm text-gray-800">
                            HSNCODE({productCompanies[0]?.hsnCode || "N/A"})
                          </td>
                          <td className="px-4 font-bold py-3 whitespace-nowrap text-sm text-gray-800">
                            {productCompanies[0]?.gstPercentage || "N/A"}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-lg bg-white p-4 shadow-lg">
            <h2 className="mb-6 text-xl font-bold text-gray-800">
              Quotation Details
            </h2>
            <form className="grid grid-cols-4 gap-2">
              <div className="col-span-1">
                <input
                  type="number"
                  id="commission"
                  placeholder="Commission Percentage"
                  className="rounded-md border p-3 shadow-sm w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={percentage}
                  onChange={(e) => calculateTotal(parseFloat(e.target.value))}
                />
              </div>
              <div className="col-span-1 flex items-center">
                <span className="font-semibold mr-2">Total Price:</span>
                <span id="total" className="text-gray-800 text-lg">
                  {total}
                </span>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="negotiation"
                  className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <span className="text-sm text-gray-500">Negotiation</span>
                  <input
                    type="checkbox"
                    id="negotiation"
                    name="negotiation"
                    className="shrink-0 ms-auto mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                </label>
              </div>
              <div className="col-span-1 items-center justify-center flex">
                <button
                  type="button"
                  title="send to Customer"
                  className="px-4 rounded-md bg-blue-600 py-3 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={handleSubmit}
                >
                  Send to Customer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
