import React from 'react'
import About from './About'
import WhyChoose from './WhyChoose'
import Contact from './Contact'
import ExploreCategory from './ExploreCategory'
import WhyServices from './WhyServies'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs"
import Testimonials from './Testimonials'
import AboutPage from './AboutPage'

export default function Utils() {
  return (
    <div className='rounded-2xl'>   
    <Tabs defaultValue="about" className="w-full">
      <TabsList className="grid w-full grid-cols-7">
        <TabsTrigger value="explore">Explore Category</TabsTrigger>
        <TabsTrigger value="about">About</TabsTrigger>
        <TabsTrigger value="why-choose">Why Choose</TabsTrigger>
        <TabsTrigger value="why-services">Services</TabsTrigger>
        <TabsTrigger value="contact">Contact</TabsTrigger>
        <TabsTrigger value="testimonials">Testimonials</TabsTrigger>
        <TabsTrigger value="aboutpage">About Page</TabsTrigger>
        
      </TabsList>
      <TabsContent value="about">
        <About />
      </TabsContent>
      <TabsContent value="why-choose">
        <WhyChoose />
      </TabsContent>
      <TabsContent value="contact">
        <Contact />
      </TabsContent>
      <TabsContent value="explore">
        <ExploreCategory />
      </TabsContent>
      <TabsContent value="why-services">
        <WhyServices />
      </TabsContent>
      <TabsContent value="testimonials">
        <Testimonials/>
      </TabsContent>
      <TabsContent value="aboutpage">
        <AboutPage/>
      </TabsContent>
    </Tabs>

    </div>
  )
}