import React, { useState, useEffect } from 'react';
import { IndianRupee, Package, Mail, Calendar, Truck, MessageSquare, ChevronDown, ChevronUp } from 'lucide-react';
import { selectSellerEmail } from '../../services/loginReducer';
import { useSelector } from 'react-redux';
import AlertMessage from '../utility/AlertMessage';
import NotFound from '../utility/NotFound'
import { useGetNegotiationsQuery, useUpdateNegotiationMutation } from '../../services/services';
const InfoRow = ({ icon, label, value }) => (
  <div className="flex items-center mb-2">
    {icon}
    <span className="font-semibold mr-2 ml-2">{label}:</span>
    <span>{value}</span>
  </div>
);



export default function Negotiation() {
  const email = useSelector(selectSellerEmail);
  const status = "pending_seller";
  const [alert, setAlert] = useState(null);
  const { data: negotiations, isLoading, refetch } = useGetNegotiationsQuery({ status, email });
  const [updateNegotiation] = useUpdateNegotiationMutation();


  useEffect(() => {
    refetch({ status, email });
  }, [email]);

  const handleSubmit = async (negotiationId, comment, newAmount) => {
    try {
      await updateNegotiation({ negotiationId, comment, newAmount });

      setAlert({
        type: "success",
        message: "Negotiation submitted successfully!",
      });
      refetch();
    }
    catch (err) {
      console.log(err);
    }
  };


  if (isLoading) {
    {
      return (<p>..loaidng</p>)
    }
  }

  return (
    <div className=" w-full mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-6">Negotiations</h1>
      <div className='grid grid-flow-row grid-cols-2 gap-4'>

        {negotiations && negotiations.map((negotiation, index) => (
          <NegotiationItem
            key={negotiation.negotiationDetails.negotiationId || index}
            negotiation={negotiation}
            onSubmit={handleSubmit}
          />
        ))}
          {negotiations.length === 0 && <NotFound title="Negotiations"/>}
      </div>
    </div>
  );
}

const NegotiationItem = ({ negotiation, onSubmit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newAmount, setNewAmount] = useState('');
  const [comment, setComment] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(negotiation._id, comment, newAmount);
    // setNewAmount('');
  };

  return (
    <div className="border rounded-lg p-4 mb-4  w-full">
      <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <h2 className="text-lg font-semibold">{negotiation.productDetails.productName}</h2>
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
      </div>

      {isExpanded && (
        <>
          <div className="mt-4">
            <h3 className="text-md font-semibold mb-2">Product Details</h3>
            <InfoRow icon={<Package className="w-4 h-4" />} label="Product ID" value={negotiation?.productDetails.productId} />
            <InfoRow icon={<Package className="w-4 h-4" />} label="HSN Code" value={negotiation?.productDetails.hsnCode} />
            <InfoRow icon={<Package className="w-4 h-4" />} label="Measurement" value={negotiation.productDetails.measurement} />
            <InfoRow icon={<IndianRupee className="w-4 h-4" />} label="GST" value={negotiation.productDetails.gst} />
          </div>

          <div className="mt-4">
            <h3 className="text-md font-semibold mb-2">Negotiation Details</h3>
            <InfoRow icon={<Package className="w-4 h-4" />} label="Negotiation ID" value={negotiation.negId} />
            <InfoRow icon={<IndianRupee className="w-4 h-4" />} label="Negotiation Amount" value={`₹${negotiation.negotiationDetails.negotiationAmount}`} />
            <InfoRow icon={<Package className="w-4 h-4" />} label="Negotiation Quantity" value={negotiation.negotiationDetails.negotiationQuantity} />
            <InfoRow icon={<IndianRupee className="w-4 h-4" />} label="Preview Amount" value={`₹${negotiation.negotiationDetails.previewAmount}`} />
            <InfoRow icon={<Package className="w-4 h-4" />} label="Preview Quantity" value={negotiation.negotiationDetails.previewQuantity} />
          </div>

          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-2">
              <label htmlFor="newAmount" className="block text-sm font-medium text-gray-700">New Negotiation Amount</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <IndianRupee className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  name="newAmount"
                  id="newAmount"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                  value={newAmount}
                  onChange={(e) => setNewAmount(e.target.value)}
                />
              </div>

            </div>
            <div>
              <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
              <textarea className=' border w-full' id="comment" onChange={(e) => setComment(e.target.value)} name="" >

              </textarea>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send New Negotiation Amount
            </button>
          </form>
        </>
      )}
    </div>
  );
};