import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { CheckCircle } from "lucide-react";

export default function ThankYou() {
  const { payid, orderId } = useParams();
  const location = useLocation();
  const { response } = location.state || {};

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const formatAmount = (amount) => {
    return (amount / 100).toFixed(2);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="text-center mb-6">
          <CheckCircle className="mx-auto h-16 w-16 text-green-500" />
          <h1 className="mt-4 text-3xl font-bold text-gray-900">Payment Successful!</h1>
          <p className="mt-2 text-gray-600">Thank you for your purchase.</p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Transaction Details</h2>
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-sm text-gray-600"><span className="font-medium">Transaction ID:</span> {payid}</p>
            <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Order ID:</span> {orderId}</p>
            {response && response.paymentDetails && (
              <>
                <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Amount:</span> ₹{formatAmount(response.paymentDetails.amount)}</p>
                <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Date:</span> {formatDate(response.paymentDetails.created_at)}</p>
                <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Payment Method:</span> {response.paymentDetails.method}</p>
              </>
            )}
          </div>
        </div>

        {response && response.orderDetails && response.orderDetails.length > 0 && (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Order Details</h2>
            <div className="bg-gray-50 p-4 rounded-md">
              <p className="text-sm text-gray-600"><span className="font-medium">Product:</span> {response.orderDetails[0].productName}</p>
              <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Quantity:</span> {response.orderDetails[0].minQty}</p>
              <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Price:</span> ₹{response.orderDetails[0].amount}</p>
            </div>
          </div>
        )}

        <div className="text-center">
          <button
            onClick={handlePrint}
            className="inline-block bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition-colors mr-4"
          >
            Print
          </button>
          <Link to="/" className="inline-block bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors">
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
}
