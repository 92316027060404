import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, Box, Button, Modal, Grid, Typography, Switch, FormControlLabel } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  useGetCategoriesQuery,
  useGetSubcategoriesQuery,
  useAddProductMutation,


} from "../../services/services";

import {
  useGetUserAllQuery, useGetProductsQuery,

  useUpdateProductMutation
} from "../../services/userApi";
import { useSelector } from "react-redux";
import { selectSellerEmail } from "../../services/loginReducer";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';

const filter = createFilterOptions();

const ProductForm = ({ setShowForm }) => {
  const email = useSelector(selectSellerEmail);
  const { data: sellerData, isLoading: sellerLoading } = useGetUserAllQuery("seller");
  const { data: productsData, isLoading: productsLoading } = useGetProductsQuery();

  const [isExistingProduct, setIsExistingProduct] = useState(false);

  const { register, handleSubmit, control, setValue, reset, watch } = useForm({
    defaultValues: {
      name: "",
      brand: "",
      email: email,
      categoryType: "",
      categorySubType: "",
      measurements: [],
      tags: [],
      cPrice: "",
      currency: "Rupees",
      grades: "Grade A",
      minQty: 0,
      img: "https://example.com/aluminum-sheet.jpg",
      location: "",
      state: "",
      region: "",
      pincode: "",
      hnscode: "",
      gstPercentage: "",
      assignedSellers: [],
      existingProduct: null,
      description: "",
    },
  });

  const [categoryValue, setCategoryValue] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  const {
    data: categoriesData = [],
    isLoading: categoriesLoading,
  } = useGetCategoriesQuery();

  const categoryId = categoryValue ? categoryValue._id : undefined;

  const {
    data: subcategoryData = [],
    isLoading: subcategoriesLoading,
  } = useGetSubcategoriesQuery(categoryId, {
    skip: !categoryId,
  });

  useEffect(() => {
    if (!subcategoriesLoading && subcategoryData.length > 0) {
      setSubCategoryOptions(subcategoryData);
    }
  }, [subcategoriesLoading, subcategoryData]);

  const [addProduct] = useAddProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const onSubmit = async (data) => {
    try {
      const sellerIds = data.assignedSellers.map(seller => seller.value);
      if (isExistingProduct && data.existingProduct) {
        // Check if assignedSellers exists and is an array, if not, initialize it as an empty array

        const existingAssignedSellers = Array.isArray(data.existingProduct.assignedSellers)
          ? data.existingProduct.assignedSellers
          : [];

        console.log(existingAssignedSellers);
        await updateProduct({
          productId: data.existingProduct._id,

          assignedSellers: [...new Set([...existingAssignedSellers, ...sellerIds])]

        });
      } else {
        console.log("Please select", sellerIds);
        const res = await addProduct({ productData: { ...data, assignedSellers: sellerIds } });
        if (res.status === 400) {
          alert(res.message);
        }
      }
      reset();
      setShowForm(false);
    } catch (error) {
      console.error("Error submitting product:", error);
    }
  };
  const sellerOptions = sellerData?.map(seller => ({
    value: seller._id,
    label: seller.name
  })) || [];

  const productOptions = productsData?.map(product => ({
    value: product._id,
    label: product.name,
    ...product
  })) || [];

  const watchExistingProduct = watch("existingProduct");

  useEffect(() => {
    if (watchExistingProduct) {
      Object.keys(watchExistingProduct).forEach(key => {
        setValue(key, watchExistingProduct[key]);
      });
    }
  }, [watchExistingProduct, setValue]);

  return (
    <Modal
      open={true}
      onClose={() => setShowForm(false)}
      aria-labelledby="product-modal-title"
      aria-describedby="product-modal-description"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "800px",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
        className="thin-scrollbar"
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {isExistingProduct ? "Assign Product to Seller" : "Add New Product"}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={isExistingProduct}
              onChange={(e) => setIsExistingProduct(e.target.checked)}
            />
          }
          label="Assign Existing Product"
        />

        <Grid container spacing={3}>
          {isExistingProduct ? (
            <Grid item xs={12}>
              <Controller
                name="existingProduct"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={productOptions}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Existing Product" fullWidth />
                    )}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="categoryType"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={categoryValue}
                      onChange={(event, newValue) => {
                        setCategoryValue(newValue);
                        setValue("categoryType", newValue?.category || "");
                      }}
                      options={categoriesData}
                      getOptionLabel={(option) => option.category}
                      renderInput={(params) => (
                        <TextField {...params} label="Category" fullWidth />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="categorySubType"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setValue("categorySubType", newValue);
                        } else if (newValue && newValue.inputValue) {
                          setValue("categorySubType", newValue.inputValue);
                        } else {
                          setValue("categorySubType", newValue?.name || "");
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      freeSolo
                      options={subCategoryOptions}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") return option;
                        if (option.inputValue) return option.inputValue;
                        return option.name;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="SubCategory" />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Name"
                  {...register("name", { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Brand"
                  {...register("brand", { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Minimum Quantity"
                  type="number"
                  {...register("minQty", { required: true, min: 1 })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="measurements"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={[
                        "kg",
                        "units",
                        "liter",
                        "meter",
                        "centimeter",
                        "mm",
                        "NOS",
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label="Measurements" fullWidth />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Cost Price"
                  type="number"
                  {...register("cPrice", { required: true, min: 0 })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={["Dollar", "Rupees", "GBP"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Currency" fullWidth />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Location"
                  {...register("location", { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Pincode"
                  {...register("pincode", { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="HSN Code"
                  {...register("hnscode", { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="GST Percentage"
                  type="number"
                  {...register("gstPercentage", { required: true, min: 0, max: 100 })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  {...register("description")}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Controller
              name="assignedSellers"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={sellerOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Assign Sellers"
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ minWidth: 200 }}
          >
            {isExistingProduct ? "Assign Product" : "Add Product"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductForm;