import { createSlice } from "@reduxjs/toolkit";

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    items: [],
  },
  reducers: {
    toggleFavorite: (state, action) => {
      const productName = action.payload;
      const index = state.items.findIndex((item) => item.name === productName);

      if (index !== -1) {
       
        state.items.splice(index, 1);
      }
     
    },
    setFavorites: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { toggleFavorite, setFavorites } = favoritesSlice.actions;

export const selectFavorites = (state) => state.favorites.items;

export default favoritesSlice.reducer;
